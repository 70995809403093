import { connect } from 'react-redux';
import {
    getAdresse1SouscripteurDemandeTarifInit, getAdresse2SouscripteurDemandeTarifInit,
    getCPSouscripteurDemandeTarifInit,
    getVilleSouscripteurDemandeTarifInit,
} from '../../../../../../redux/selectors/init/init.selectors.js';
import AdresseActuelle from './AdresseActuelle.jsx';

const mapStateToProps = (state) => {
    return {
        adresse1: getAdresse1SouscripteurDemandeTarifInit(state),
        adresse2: getAdresse2SouscripteurDemandeTarifInit(state),
        ville: getVilleSouscripteurDemandeTarifInit(state),
        cp: getCPSouscripteurDemandeTarifInit(state)
    };
};

const AdresseActuelleContainer = connect(mapStateToProps)(AdresseActuelle);

export default AdresseActuelleContainer;
