import info from '@amo/core/assets/images/AMO_Icons/Online-activation.svg';
import { BulleAide } from '@amo/core/components/containers';
import { Radio, Text } from '@amo/core/components/forms';
import {
    isBeforeAv,
    maxDate30Days,
    minDate6Months,
    minDateHourToday
} from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import { useEffect, useState } from "react";

const DateHeureEffet = (props) => {
    const { source, changeValue, data, dateHeureEffetError, dateEffetContrat, heureEffetContrat } = props;
    const motifAvenant = _.get(data, 'DemandeTarif[Police][MotifAvenant]');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');

    useEffect(() => {
        if (selectedDate && selectedTime) {
            const combinedDateTime = `${selectedDate} ${selectedTime}`;
            changeValue('DemandeContrat[DateHeureEffet]', combinedDateTime);
        }
    }, [selectedDate, selectedTime, changeValue]);

    useEffect(() => {
        if (motifAvenant === '8') {
            // par défaut la date du jour (si on est dans le passé par rapport à la date de situation contrat)
            let defaultDate = moment().format('DD/MM/YYYY');
            let defaultTime = moment().format('HH:mm');

            // si la date de la dernière situation est le jour J par rapport à la date du jour → mettre date d'effet de la nouvelle pièce à J+1 de la date de dernière situation
            if (moment(dateEffetContrat, 'DD/MM/YYYY').isSame(moment(), 'day')) {
                defaultDate = moment(dateEffetContrat, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
                defaultTime = moment(heureEffetContrat, 'HH:mm').format('HH:mm');
            // si la date de la dernière situation est dans le futur par rapport à la date du jour → mettre la date de la nouvelle pièce à la même date que la dernière situation
            } else if (moment(dateEffetContrat, 'DD/MM/YYYY').isAfter(moment(), 'day')) {
                defaultDate = moment(dateEffetContrat, 'DD/MM/YYYY').format('DD/MM/YYYY');
                defaultTime = moment(heureEffetContrat, 'HH:mm').format('HH:mm');
            }

            changeValue('DemandeContrat[DateHeureEffet]', `${defaultDate} ${defaultTime}`);
            changeValue('DateEffetContrat', defaultDate);
            changeValue('HeureEffetContrat', defaultTime);
        }
    }, [motifAvenant, changeValue, dateEffetContrat, heureEffetContrat]);

    return (
        <>
            {motifAvenant !== '8' && (
                <div className={'row justify-content-center'}>
                    <h3 className={'f-16 text-center fw-bold mt-5'}>Quand souhaitez-vous faire débuter votre avenant ?</h3>
                    <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                        <Field
                            component={Radio}
                            type={'radio'}
                            name={`debutContrat`}
                            value={'1'}
                            label={'Maintenant'}
                            className={'small'}
                            onClick={() => {
                                changeValue('DateEffetContrat', moment().add(15, 'minutes').format('DD/MM/YYYY'))
                                changeValue('HeureEffetContrat', moment().add(15, 'minutes').format('HH:mm'))
                            }}
                        />
                    </div>
                    <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                        <Field
                            component={Radio}
                            type={'radio'}
                            name={`debutContrat`}
                            value={'0'}
                            label={'A un autre jour'}
                            className={'small'}
                            onClick={() => {
                                changeValue('DateEffetContrat', '')
                                changeValue('HeureEffetContrat', '')
                            }}
                        />
                    </div>
                </div>
            )}
            {motifAvenant !== '8' && _.get(data, 'debutContrat') && (
                <div className={'row justify-content-center'}>
                    <div className={'col-lg-4 col-md- col-sm-6 col-6 my-2'}>
                        <Field
                            name={`DateEffetContrat`}
                            label={`Date d'effet de votre contrat`}
                            component={Text}
                            type={'text'}
                            typeFormat={'date'}
                            placeholder={'Exemple : 22/03/2023'}
                            maxLength={10}
                            disabled={_.get(data, 'debutContrat') === '1'}
                            validate={
                                source === 'conseiller'
                                    ? [isBeforeAv, maxDate30Days, minDate6Months]
                                    : [isBeforeAv, maxDate30Days, minDateHourToday]
                            }
                            onChange={(e) => setSelectedDate(e.target.value)}
                            value={selectedDate} // Ajout de la valeur par défaut
                            required
                        />
                    </div>
                    <div className={'col-lg-4 col-md- col-sm-6 col-6 my-2'}>
                        <Field
                            name={`HeureEffetContrat`}
                            label={`Heure d'effet de votre contrat`}
                            component={Text}
                            type={'text'}
                            typeFormat={'hour'}
                            placeholder={'Exemple : 12:20'}
                            maxLength={5}
                            disabled={_.get(data, 'debutContrat') === '1'}
                            onChange={(e) => setSelectedTime(e.target.value)}
                            value={selectedTime} // Ajout de la valeur par défaut
                            required
                        />
                    </div>
                    {{dateHeureEffetError} && <span id="dateHeureEffetError" className="text-danger f-13 mb-2 ">{dateHeureEffetError}</span>}
                </div>
            )}
            {motifAvenant === '8' && (
                <div className={'row justify-content-center'}>
                    <div className={'col-lg-4 col-md- col-sm-6 col-6 my-2'}>
                        <Field
                            name={`DateEffetContrat`}
                            label={`Date d'effet de votre contrat`}
                            component={Text}
                            type={'text'}
                            typeFormat={'date'}
                            placeholder={'Exemple : 22/03/2023'}
                            maxLength={10}
                            validate={
                                source === 'conseiller'
                                    ? [isBeforeAv, maxDate30Days, minDate6Months]
                                    : source === 'site'
                                        ? [isBeforeAv, maxDate30Days, minDateHourToday]
                                        : [isBeforeAv, maxDate30Days, minDateHourToday]
                            }
                            onChange={(e) => setSelectedDate(e.target.value)}
                            required
                            disabled
                        />
                    </div>
                    <div className={'col-lg-4 col-md- col-sm-6 col-6 my-2'}>
                        <Field
                            name={`HeureEffetContrat`}
                            label={`Heure d'effet de votre contrat`}
                            component={Text}
                            type={'text'}
                            typeFormat={'hour'}
                            placeholder={'Exemple : 12:20'}
                            maxLength={5}
                            onChange={(e) => setSelectedTime(e.target.value)}
                            required
                            disabled
                        />
                    </div>
                    {{dateHeureEffetError} && <span id="dateHeureEffetError" className="text-danger f-13 mb-2 ">{dateHeureEffetError}</span>}
                </div>
            )}
            <BulleAide
                icon={info}
                title={'Vous allez recevoir un lien de signature électronique pour votre avenant.'}
                text={'Après avoir validé votre avenant, vous allez recevoir un lien de signature électronique pour signer votre avenant. Le code sera envoyé sur votre numéro de téléphone.'}
                className={'mt-0 col-lg-8 offset-lg-2 col-md-12'}
            />
        </>
    );
};

export default DateHeureEffet;
