import { normalizeEuro } from '@amo/core/utils/normalize';
import _ from 'lodash';
import { useState } from 'react';
import LastOptions from './LastOptions';

const Souscrire = (props) => {
    const {
        codePromo,
        loading,
        formuleChoisie,
        postFill,
        changeValue,
        data,
        prixTotalMensuel,
        prixTotalMensuelPromo,
        prixTotalAnnuel,
        prixTotalAnnuelPromo,
        nbOptions,
        options,
    } = props;
    const [showLastOptions, setShowLastOptions] = useState(false);
    const [typeSubscribe, setTypeSubscribe] = useState(null);
    const optionsChoisi = _.get(data, 'options');

    const validateStepTarif = (type) => {
        if (
            (!optionsChoisi?.PCC && _.find(options, ['idGroupe', 'PCC'])) ||
            (!optionsChoisi?.ASS && _.find(options, ['idGroupe', 'ASS']))
        ) {
            setTypeSubscribe(type);
            setShowLastOptions(true);
        } else {
            setShowLastOptions(false);
            changeValue('typeDevis', type);
            postFill({ getTarif: false });
        }
    };

    return (
        <div id={'souscrire'} className={'container sticky-bottom'}>
            <div className={`row mt-4`}>
                <div className={'col-12'}>
                    <div className={'bg-white border rounded p-lg-4 py-3 px-0 row align-items-center'}>
                        {/* Desktop */}
                        <div className="col-12 col-lg-2 text-start mb-3 mb-lg-0 d-none d-lg-block">
                            <p className={'f-14 fw-bold'}>Votre formule</p>
                            <span className="f-24 fw-bold">
                                {formuleChoisie && formuleChoisie.LibelleFormule?.toUpperCase()}
                            </span>
                            {nbOptions >= 1 && (
                                <p className={'f-14 text-secondary'}>
                                    + {nbOptions} option{nbOptions > 1 ? 's' : ''}
                                </p>
                            )}
                        </div>
                        {/* Mobile */}
                        <div className="col-12 col-lg-4 text-start mb-3 mb-lg-0 d-block d-lg-none">
                            <p className={'f-14 fw-bold'}>
                                Votre formule{' '}
                                <span className="f-24 fw-bold">
                                    {formuleChoisie && formuleChoisie.LibelleFormule?.toUpperCase()}
                                </span>{' '}
                                {nbOptions >= 1 && (
                                    <span className={'f-14 text-secondary'}>
                                        + {nbOptions} option{nbOptions > 1 ? 's' : ''}
                                    </span>
                                )}
                            </p>
                        </div>
                        <div className="d-none d-lg-block col-12 col-lg-2 text-start mb-3 mb-lg-0">
                            {_.get(data, 'DemandeTarif[Police][FractionnementChoisi]') === 'M' &&
                                <div className={'mois'}>
                                    {codePromo?.Eligible && prixTotalMensuelPromo < prixTotalMensuel ? (
                                        <>
                                            <span className={'f-30 fw-bold text-secondary'}>
                                                {normalizeEuro(prixTotalMensuelPromo)}
                                            </span>
                                            <small className={'f-10'}>/mois*</small>
                                            <small className={'d-block f-14 text-decoration-line-through'}>
                                                {normalizeEuro(prixTotalMensuel)}/mois*
                                            </small>
                                        </>
                                    ) : (
                                        <>
                                            <span className={'f-30 fw-bold text-secondary'}>
                                                {normalizeEuro(prixTotalMensuelPromo)}
                                            </span>
                                            <small className={'f-10'}>/mois*</small>
                                        </>
                                    )}
                                    <div className={'d-block'}>
                                        <span className={'f-14 fw-bold text-secondary'}>
                                            soit {normalizeEuro(prixTotalMensuelPromo*12)}
                                        </span>
                                        <small className={'f-10'}>/an*</small>
                                    </div>
                                </div>
                            }

                            {_.get(data, 'DemandeTarif[Police][FractionnementChoisi]') === 'A' && (
                                <div className={'an'}>
                                    {codePromo?.Eligible && prixTotalAnnuelPromo < prixTotalAnnuel ? (
                                        <>
                                            <span className={'f-30 fw-bold text-secondary'}>
                                                {normalizeEuro(prixTotalAnnuelPromo)}
                                            </span>
                                            <small className={'f-10'}>/an*</small>
                                            <small className={'d-block f-14 text-decoration-line-through'}>
                                                {normalizeEuro(prixTotalAnnuel)}/an*
                                            </small>
                                        </>
                                    ) : (
                                        <>
                                            <span className={'f-30 fw-bold text-secondary'}>
                                                {normalizeEuro(prixTotalAnnuel)}
                                            </span>
                                            <small className={'f-10'}>/an*</small>
                                        </>
                                    )}
                                    <div className={'d-block'}>
                                        <span className={'f-14 fw-bold text-secondary'}>
                                            soit {normalizeEuro(prixTotalMensuelPromo)}
                                        </span>
                                        <small className={'f-10'}>/mois*</small>
                                    </div>
                                </div>
                            )}
                            <small className={'f-12'}>*Prix TTC</small>
                        </div>
                        <div className={'col-12 col-lg-8 text-center align-content-end align-items-center'}>
                            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            ) : (
                                <div className={'row text-center d-block align-items-center'}>
                                    <button
                                        className={`btn btn-primary btn-smaller btn-arrow mb-2 col-lg-4 col-8 offset-1 offset-lg-0`}
                                        onClick={() => validateStepTarif('subscribe')}
                                    >
                                        Finaliser l'avenant
                                    </button>
                                    <button
                                        className={`btn btn-secondary btn-smaller btn-arrow col-4 offset col-lg-4 col-9 offset-1 offset-lg-1`}
                                        onClick={() => validateStepTarif('devis')}
                                        style={{ padding: '14px 10px' }}
                                    >
                                        Recevoir ma proposition par email
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {showLastOptions && (
                <LastOptions {...props} setShowLastOptions={setShowLastOptions} typeSubscribe={typeSubscribe} />
            )}
        </div>
    );
};

export default Souscrire;
