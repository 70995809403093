export const SAVE_DEVIS = '[Save Devis]';
export const POST_SAVE_DEVIS = `${SAVE_DEVIS} Post`;

export const postSaveDevis = ({ form, query, step }) => ({
    type: POST_SAVE_DEVIS,
    payload: {
        data: query,
        body: form,
        meta: {
            otherData: {
                devisOrSubscribe: form.typeDevis,
                step,
            },
        },
    },
});
