import _ from 'lodash';
import IdentiqueStationnement from './IdentiqueStationnement/IdentiqueStationnement.jsx';
import LieuStationnement from './LieuStationnement/LieuStationnement.jsx';
import NouvelleAdresse from "./NouvelleAdresse/NouvelleAdresse.jsx";
import {UsageVehicule} from "@amo/core/components/questions";
import ContratNomSociete from "../ChangementUsage/ContratNomSociete/ContratNomSociete.jsx";
import TypeVehicule from "../TypeVehicule/TypeVehicule.jsx";
import MarqueVehiculeContainer from "../MarqueVehicule/MarqueVehiculeContainer.js";
import CylindreeVehiculeContainer from "../CylindreeVehicule/CylindreeVehiculeContainer.js";
import ModeleVehiculeContainer from "../ModeleVehicule/ModeleVehiculeContainer.js";

const ChangementAdresse = (props) => {
    const { data, id, source } = props;

    return (
        <div id={id}>
            <NouvelleAdresse {...props} />
            <IdentiqueStationnement {...props} />
            {_.get(data, 'sameStationnement') === '0' && <LieuStationnement {...props} />}
            {!_.includes(['quad', 'verte'], data?.typeVehicule) &&
                <>
                    <UsageVehicule title={'A quelle(s) <b>occasion(s)</b> <br/>utilisez-vous <b>votre véhicule</b>&nbsp;?'} {...props} />
                    {((source === 'courtier' || source === 'site') && _.get(data, 'DemandeTarif[Vehicule][UsageVehicule]') === '3') && <ContratNomSociete {...props} />}
                </>
            }
        </div>
    );
};

export default ChangementAdresse;
