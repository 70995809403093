import { connect } from 'react-redux';
import { FILL } from '../../../../../../redux/actions/app/fill/fill.actions';
import { getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import Souscrire from './Souscrire';

const mapStateToProps = (state) => {
    return {
        loading: getLoadingEntity(state, FILL),
    };
};

const SouscrireContainer = connect(mapStateToProps)(Souscrire);

export default SouscrireContainer;
