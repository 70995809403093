import Chat from '@amo/core/assets/images/AMO_Icons/Chat.svg';
import { Question } from '@amo/core/components/containers';
import _ from "lodash";
import {getStepBySlug} from "../../../../../../utils/function.js";

const GoToCoordonnees = (props) => {
    const { nextQuestion } = props;

    return (
        <Question
            icon={Chat}
            title={"Le véhicule n'a pas pu être rechargé correctement (pas de référence, véhicule inactif..). Merci de le resélectionner"}
        >
            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion(300)}>
                Selectionner le vehicule
            </button>
        </Question>
    );
};

export default GoToCoordonnees;
