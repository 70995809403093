import card from '@amo/core/assets/images/icons/card.svg';

const RecapTarif = (props) => {
    const {
        goTo,
        formuleChoisie,
        fractionnementChoisi,
        codePromo,
        prixTotalMensuelPromo,
        prixTotalMensuel,
        prixTotalAnnuelPromo,
        prixTotalAnnuel,
    } = props;

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2'}>
                <div className={'p-3 bg-white rounded border'}>
                    <div className={'row'}>
                        <div className="col-2">
                            <img src={card} alt="Informations tarif" className={'pt-2'} />
                        </div>

                        <div className="col-10 text-start">
                            <p className={'f-16 text-dark text-start fw-bold'}>
                                Votre formule{' '}
                            </p>
                            <small className={'d-block f-13 text-dark fw-bold'}>
                                Formule {formuleChoisie.LibelleFormule}
                                {/*<span className={"text-grey f-13 float-end mt-1"}>
                                    {fractionnementChoisi === 'M' ? ' / mois' : ' / an'}
                                </span>*/}
                                {/*<span className={"text-secondary f-16 float-end"}>
                                    {fractionnementChoisi === 'M'
                                        ? codePromo?.Eligible ? normalizeEuro(prixTotalMensuelPromo) : normalizeEuro(prixTotalMensuel)
                                        : codePromo?.Eligible ? normalizeEuro(prixTotalAnnuelPromo) : normalizeEuro(prixTotalAnnuel)
                                    }
                                </span>*/}
                            </small>
                            <small className={'d-block f-13'}>+ options</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecapTarif;
