import Chat from '@amo/core/assets/images/AMO_Icons/Chat.svg';
import { Question } from '@amo/core/components/containers';
import _ from "lodash";
import {getStepBySlug} from "../../../../../../utils/function.js";

const GoToCoordonnees = (props) => {
    const { nextQuestion, goTo, data, codeProduit } = props;
    const motifAvenant = _.get(data, 'DemandeTarif[Police][MotifAvenant]')

    const onClickNext = () => {
        if(motifAvenant === '8' || motifAvenant === '11'){
            if(Number(data?.cylindreeVehicule) > 50 && !_.includes(['7311_MOT1', '7311_PVK', '7311_PVE'], codeProduit)) {
                goTo({ step: getStepBySlug('vos-antecedents'), question: 0 })
            } else {
                goTo({ step: getStepBySlug('votre-tarif'), question: 0 })
            }
        } else {
            nextQuestion(300)
        }
    };

    return (
        <Question
            icon={Chat}
            title={"<b>C'est bon</b>, nous avons tout ce qu'il nous faut sur <b>votre demande d'avenant</b> 🙂"}
        >

            {(motifAvenant !== '8' && motifAvenant !== '11') &&
                <div className={'row justify-content-center'}>
                    <div className={'col-lg-10 col-md-12'}>
                        <p className={'mt-3 f-18'}>
                            Nous allons maintenant vérifier si nos informations vous concernant sont encore correctes.
                        </p>
                    </div>
                </div>
            }

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => onClickNext()}>
                Allons-y !
            </button>
        </Question>
    );
};

export default GoToCoordonnees;
