import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { FILL } from '../../../../../redux/actions/app/fill/fill.actions';
import { TARIF, postReloadTarif } from '../../../../../redux/actions/app/tarif/tarif.actions';
import {
    getApporteur1Init,
    getApporteur2Init,
    getIDDevisDevisInit,
} from '../../../../../redux/selectors/init/init.selectors';
import {
    getFormuleFranchises,
    getFormuleGarantiesACCESSEQP,
    getFormuleGarantiesASS,
    getFormuleGarantiesKSM,
    getFormuleGarantiesPCC,
    getFormuleGarantiesSelected,
    getFormuleGarantiesVALMAJ,
    getFormulePreconiseeTarif, getGarantiesInfoFranchiseDTA, getGarantiesInfoFranchiseVI,
    getGarantiesOption,
    getInfoCodePromo,
    getListeFormulesTarif,
} from '../../../../../redux/selectors/tarif/tarif.selectors';
import {
    getLoadingEntity,
    getPrixTotal,
    getPrixTotalAnnuel,
    getPrixTotalAnnuelPromo,
    getPrixTotalPromo,
} from '../../../../../redux/selectors/ui/ui.selectors';
import { getVehicule } from '../../../../../redux/selectors/vehicule/vehicule.selectors';
import TextOptions from '@amo/core/utils/json/options.json';
import Tarif from './Tarif';
import { reformatFormules } from './utilsTarif/reformatFormules';
import { reformatGaranties } from './utilsTarif/reformatGaranties';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis-avenant');
    const codeFormuleChoisie = selector(state, 'DemandeTarif[Police][FormuleChoisie]');
    const fractionnement = selector(state, 'DemandeTarif[Police][FractionnementChoisi]');

    const garantiesOptionDispo =
        getLoadingEntity(state, TARIF) === false ? getGarantiesOption(state, codeFormuleChoisie) : [];
    const options = _.filter(selector(state, 'options'), (item) => {
        return item !== undefined && _.find(garantiesOptionDispo, ['CodeGarantie', item]);
    });
    const optionsListe = _.filter(selector(state, 'optionsListe'), (item, key) => {
        return item !== undefined && item !== '' && _.find(garantiesOptionDispo, ['CodeGarantie', key]);
    });
    
    return {
        loaded: getLoadingEntity(state, TARIF) === false,
        codePromo: getInfoCodePromo(state),
        loadingFill: getLoadingEntity(state, FILL),
        vehicule: getVehicule(state),
        fractionnementChoisi: fractionnement,
        formules: reformatFormules(getListeFormulesTarif(state), selector(state, `typeVehicule`)),
        formulePreconisee: getFormulePreconiseeTarif(state),
        garantiesSelected: getFormuleGarantiesSelected(state, codeFormuleChoisie),
        TextOptions,
        codeFormuleChoisie,
        options: [
            reformatGaranties(
                getFormuleGarantiesASS(state, codeFormuleChoisie),
                fractionnement,
                selector(state, `typeVehicule`),
            ),
            reformatGaranties(
                getFormuleGarantiesPCC(state, codeFormuleChoisie),
                fractionnement,
                selector(state, `typeVehicule`),
            ),
            reformatGaranties(
                getFormuleGarantiesACCESSEQP(state, codeFormuleChoisie),
                fractionnement,
                selector(state, `typeVehicule`),
            ),
            reformatGaranties(
                getFormuleGarantiesKSM(state, codeFormuleChoisie),
                fractionnement,
                selector(state, `typeVehicule`),
            ),
            reformatGaranties(
                getFormuleGarantiesVALMAJ(state, codeFormuleChoisie),
                fractionnement,
                selector(state, `typeVehicule`),
            ),
        ],
        franchises: reformatGaranties(
            getFormuleFranchises(state, codeFormuleChoisie),
            fractionnement,
            selector(state, `typeVehicule`),
        ),
        prixTotalMensuel: getPrixTotal(state),
        prixTotalMensuelPromo: getPrixTotalPromo(state),
        prixTotalAnnuel: getPrixTotalAnnuel(state),
        prixTotalAnnuelPromo: getPrixTotalAnnuelPromo(state),
        nbOptions:
            options && _.isArray(options)
                ? options.length + (optionsListe && _.isArray(optionsListe) ? optionsListe.length : 0)
                : 0,
        apporteur2: getApporteur2Init(state),
        apporteur1: getApporteur1Init(state),
        IDDevis: getIDDevisDevisInit(state),
        infoDTA: getGarantiesInfoFranchiseDTA(state, codeFormuleChoisie),
        infoVI: getGarantiesInfoFranchiseVI(state, codeFormuleChoisie),
    };
};

const mapDispatchToProps = (dispatch, ownProps) =>
    bindActionCreators(
        {
            postReloadTarif: ({ form, query }) => postReloadTarif({ form, query, source: ownProps.source }),
        },
        dispatch,
    );

const TarifContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Tarif);

export default TarifContainer;
