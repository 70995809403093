import { Text } from '@amo/core/components/forms';
import { minLentghImmatriculation, validateImmatriculation } from '@amo/core/utils/validateField';
import { Field } from 'redux-form';

export const Immatriculation = ({required}) => {
    return (
        <>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                    <Field
                        name={`DemandeTarif[Vehicule][Immatriculation]`}
                        label={"N° d'immatriculation du véhicule"}
                        placeholder={'EX: AA 123 BB'}
                        component={Text}
                        type={'text'}
                        typeFormat={'immat'}
                        validate={[minLentghImmatriculation, validateImmatriculation]}
                        required={required}
                    />
                </div>
            </div>
        </>
    );
};