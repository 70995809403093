import indemnisation from '@amo/core/assets/images/icons/indemnisation.png';
import { BulleAide, Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

export const IndemAccidentResponsable = (props) => {
    const { nextQuestion, data } = props;

    return (
        <Question
            icon={indemnisation}
            title={
                'En cas d’<b>accident responsable</b> qui pourrait engendrer de <b>graves blessures</b> voire même <b>un décès</b>&nbsp;?'
            }
            subtitle={`Souhaitez vous être indemnisé...`}
            position={'bottom'}
        >
            <div className={'row justify-content-center'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[DevoirConseil][DevoirIC]'}
                        value={'1'}
                        label={'Oui'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[DevoirConseil][DevoirIC]'}
                        value={'0'}
                        label={'Non'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[DevoirConseil][DevoirIC]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>

            <BulleAide
                title="Si vous êtes victime et blessé(e) suite à un accident causé par un autre véhicule, vos blessures seront indemnisées par son assureur."
                text="Mais si vous êtes le responsable ou que vous avez provoqué seul cet accident, les impacts financiers d'une invalidité ou même d'un décès peuvent être importants pour vous et vos proches. Vous pouvez vous assurer pour faire face à ces conséquences financières."
            />
        </Question>
    );
};
