import Motards from '@amo/core/assets/images/AMO_Icons/Tout-motards.svg';
import { Question } from '@amo/core/components/containers';
import {getInformationVehicule} from "@amo/core/utils/index.js";

const InformationsVehicule = (props) => {
    const { nextQuestion, vehicule } = props;

    return (
        <Question icon={Motards} title={""}>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-3 f-18 text-start'} dangerouslySetInnerHTML={{ __html: getInformationVehicule(vehicule) }} />
                </div>
            </div>

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                Allons-y !
            </button>
        </Question>
    );
};

export default InformationsVehicule;
