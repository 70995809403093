import { Text } from '@amo/core/components/forms';
import { normalizeNumMarquage } from '@amo/core/utils/normalize.js';
import { Field } from 'redux-form';

export const NumeroSerie = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    name={`DemandeTarif[Vehicule][VIN]`}
                    label={'N° de série'}
                    component={Text}
                    type={'text'}
                    typeFormat={'immat'}
                    normalize={normalizeNumMarquage}
                />
            </div>
        </div>
    );
};
