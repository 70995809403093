import info from '@amo/core/assets/images/AMO_Icons/Searching.svg';
import {  Question } from '@amo/core/components/containers';
import { LargeSpinner } from '@amo/core/components/loaders';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {NumeroSerie, Immatriculation} from '@amo/core/components/questions';
import getFormuleChoisie from '../Tarif/utilsTarif/getFormuleChoisie';
import DateHeureEffet from './DateHeureEffet/DateHeureEffet';
import RecapPersonne from './RecapPersonne/RecapPersonne';
import RecapTarif from './RecapTarif/RecapTarif';
import RecapVehicule from './RecapVehicule/RecapVehicule';

const Recapitulatif = (props) => {
    const {
        data,
        postPaySubmit,
        formules,
        payLoaded,
        nomPrenom,
        changeValue,
        isLoadedTarif,
        queryTarif,
        postReloadTarif
    } = props;
    const formuleChoisie = getFormuleChoisie(data, formules);
    const [tryPost, setTryPost] = useState(false);
    const [dateHeureEffetError, setDateHeureEffetError] = useState(null);

    useEffect(() => {
        if (tryPost) {
            isNotValid();
        }
    }, [data, tryPost]);

    useEffect(() => {
        changeValue('DemandeContrat[NomTitulaire]', nomPrenom);
        if (!isLoadedTarif) {
            postReloadTarif({ form: data, query: queryTarif });
        }
    }, []);

    /* Function qui ajoute la class error sur les champs vide */
    const handleError = (fieldName, remove) => {
        _.forEach(document.getElementsByName(fieldName), (input) => {
            if (input.getAttribute('type') === 'radio') {
                if (remove) {
                    input.labels[0].classList.remove('error');
                } else {
                    input.labels[0].classList.add('error');
                }
            } else {
                if (remove) {
                    input.classList.remove('error');
                } else {
                    input.classList.add('error');
                }
            }
        });
        if (fieldName === 'DemandeContrat[DateHeureEffet]') {
            if (remove) {
                setDateHeureEffetError(null);
            } else {
                setDateHeureEffetError("Pensez à saisir l'heure en indiquant JJ/MM/YYYY 00:00");
            }
        }
    };

    const isNotValid = () => {
        let notValid = false;
        let fields = [
            `DemandeContrat[DateHeureEffet]`,
        ];

        if (!_.includes(['quad', 'verte'], data?.typeVehicule)) {
            fields.unshift(`DemandeTarif[Vehicule][Immatriculation]`);
        }

        if (_.get(data, 'DemandeTarif[Police][MotifAvenant]') !== '8') {
            fields.push('debutContrat');
        }

        fields.forEach((field) => {
            if (!_.get(data, field) && field !== '') {
                handleError(field);
                notValid = true;
            } else {
                handleError(field, true);
            }
            if (field === 'DemandeContrat[BIC]' && !_.get(data, field)) {
                document.getElementById('btn-check-iban').style.color = 'red';
                document.getElementById('btn-check-iban').style.borderColor = 'red';
                document.getElementById('btn-check-iban').style.backgroundColor = 'transparent';
            }
        });

        if (!moment(_.get(data, 'DemandeContrat[DateHeureEffet]'), 'DD/MM/YYYY HH:mm', true).isValid() || !_.get(data, 'DateEffetContrat') || !_.get(data, 'HeureEffetContrat')) {
            handleError('DemandeContrat[DateHeureEffet]');
            notValid = true;
        }

        return notValid;
    };

    const myPostPaySubmit = () => {
        setTryPost(true);
        if (isNotValid()) {
            document.getElementsByClassName('error')[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            postPaySubmit();
        }
    };

    const hasErrorOnPage = () => {
        const errorDivs = document.querySelectorAll('.alert.alert-danger.invalid-feedback.d-block');
        return errorDivs.length > 0;
    };

    return (
        <>
            {isLoadedTarif ? (
                <>
                    {payLoaded ? (
                        <LargeSpinner message={'Patientez quelques instants'} />
                    ) : (
                        <div id={'etape-recap'}>
                            <Question loader icon={info} title={'Récapitulatif'}>
                                <RecapVehicule {...props} />

                                <RecapPersonne {...props} />

                                <RecapTarif {...props} formuleChoisie={formuleChoisie} />

                                {((_.get(data, 'DemandeTarif[Vehicule][VehiculeImmatricule]') == '1' && _.includes(['quad', 'verte'], data?.typeVehicule)) || (!_.includes(['quad', 'verte'], data?.typeVehicule))) &&
                                    <>
                                        <h3 className={'f-16 text-center fw-bold mt-5'}>Informations Complémentaires</h3>
                                        <Immatriculation {...props} required />
                                    </>
                                }

                                <DateHeureEffet {...props} formuleChoisie={formuleChoisie} dateHeureEffetError={dateHeureEffetError}/>
                            </Question>

                            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => myPostPaySubmit()} disabled={hasErrorOnPage()}>
                                Souscrire
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <LargeSpinner message={'Nous rechargeons votre tarif !'} />
            )}
        </>
    );
};

export default Recapitulatif;
