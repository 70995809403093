import _ from 'lodash';
import RouteStep from '../components/App/Init/RouteStep';
import textSource from '../utils/json/textSource';

export const getTexteBySource = (source, question) => {
    let newSource = source === 'conseiller' || source === 'concessionnaire' ? 'courtier' : source;
    return textSource[0][question][newSource];
};

export const routesAffichees = (routes) => {
    let cloneRoute = _.cloneDeep(routes);
    cloneRoute = _.reject(cloneRoute, ['slug', 'faq']);
    return cloneRoute;
};

export const getStepBySlug = (slug) => {
    const route = _.find(RouteStep, ['slug', slug]);
    return route && route !== -1 ? route.step : '';
};

export const getSlugByStep = (step) => {
    const route = _.find(RouteStep, ['step', step]);
    return route && route !== -1 ? route.slug : '';
};
