import {
    AnnulationPermis,
    CondamnationAlcoolemie,
    CondamnationConduiteSansAssurance,
    CondamnationDelitFuite,
    CondamnationStupefiants,
    InformationsAnnulationPermis,
    InformationsResiliationAncienAssureur,
    InformationsSuspensionPermis,
    ResiliationAncienAssureur,
    Sinistres,
    SinistresAutoMoto,
    SuspensionPermis,
    TauxAlcool
} from "@amo/core/components/questions";
import _ from "lodash";
import {useEffect} from "react";

const Aggravations = (props) => {
    const { data, changeValue, initialValues } = props;

    const antecedentsSur3ans =
        _.get(initialValues, 'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]') === '36' ||
        _.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]') === '36' ||
        _.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '1' ||
        _.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '1' ||
        _.get(data, 'DemandeTarif[Antecedents][ResiliationAssureur]') === '1' ||
        Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]')) > 1 ||
        Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]')) > 1;

    useEffect(() => {
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]', antecedentsSur3ans ? '36' : '24')
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', antecedentsSur3ans ? '36' : '24')
    }, [antecedentsSur3ans]);

    return (
        <>
            <SuspensionPermis {...props} />
            {_.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '1' && (
                <InformationsSuspensionPermis {...props} />
            )}

            <AnnulationPermis {...props} />
            {_.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '1' && (
                <InformationsAnnulationPermis {...props} />
            )}

            {(_.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '1' ||
                _.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '1') && (
                <>
                    <CondamnationAlcoolemie {...props} />

                    {_.get(data, 'DemandeTarif[Antecedents][CondamnationAlcoolemie]') === '1' && (
                        <TauxAlcool {...props} />
                    )}
                    <CondamnationStupefiants {...props} />
                    <CondamnationDelitFuite {...props} />
                    <CondamnationConduiteSansAssurance {...props} />
                </>
            )}

            <ResiliationAncienAssureur {...props} />

            {_.get(data, 'DemandeTarif[Antecedents][ResiliationAssureur]') === '1' && (
                <InformationsResiliationAncienAssureur {...props} />
            )}

            <>
                <Sinistres {...props} antecedentsSur3ans={antecedentsSur3ans} />

                {_.get(data, 'sinistre_auto') === '1' && (
                    <SinistresAutoMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />
                )}
            </>
        </>
    )
}

export default Aggravations