import Aide from '@amo/core/assets/images/AMO_Icons/Aide.svg';
import {Question} from '@amo/core/components/containers';
import {Radio} from '@amo/core/components/forms';
import {Field} from 'redux-form';
import _ from "lodash";
import {useState} from "react";

const ChoixQuestions = (props) => {
    const { data, onSelectionChange, nextQuestion } = props
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckChange = (event) => {
        const { name, checked } = event.target;

        setCheckedItems(prevState => ({
            ...prevState,
            [name]: checked
        }));

        onSelectionChange({
            ...checkedItems,
            [name]: checked
        });
    };

    const isItemChecked = (name) => !!checkedItems[name];

    return (
        <Question icon={Aide} title={'Veuillez <b>sélectionner la ou les modifications</b> à apporter sur <b>le contrat</b>'}>
            <div className={'row justify-content-start mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        component={Radio}
                        type={'checkbox'}
                        name={'vehicule_marque_cylindree_modele'}
                        value={'vehicule_marque_cylindree_modele'}
                        label={'Véhicule (marque, modèle ou cylindrée)'}
                        className={'small'}
                        onChange={handleCheckChange}
                        checked={isItemChecked('vehicule_marque_cylindree_modele')}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        component={Radio}
                        type={'checkbox'}
                        name={'date_mise_circulation'}
                        value={'date_mise_circulation'}
                        label={'Date de mise en circulation'}
                        className={'small'}
                        onChange={handleCheckChange}
                        checked={isItemChecked('date_mise_circulation')}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        component={Radio}
                        type={'checkbox'}
                        name={'date_achat'}
                        value={'date_achat'}
                        label={'Date d\'acquisition'}
                        className={'small'}
                        onChange={handleCheckChange}
                        checked={isItemChecked('date_achat')}
                    />
                </div>
                {_.includes(['quad', 'verte'], data?.typeVehicule) &&
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <Field
                            component={Radio}
                            type={'checkbox'}
                            name={'valeur_vehicule'}
                            value={'valeur_vehicule'}
                            label={'Valeur du véhicule'}
                            className={'small'}
                            onChange={handleCheckChange}
                            checked={isItemChecked('valeur_vehicule')}
                        />
                    </div>
                }
                <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        component={Radio}
                        type={'checkbox'}
                        name={'client_proprietaire'}
                        value={'client_proprietaire'}
                        label={'Propriétaire depuis plus de 3 mois'}
                        className={'small'}
                        onChange={handleCheckChange}
                        checked={isItemChecked('client_proprietaire')}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        component={Radio}
                        type={'checkbox'}
                        name={'usage'}
                        value={'usage'}
                        label={'Usage'}
                        className={'small'}
                        onChange={handleCheckChange}
                        checked={isItemChecked('usage')}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        component={Radio}
                        type={'checkbox'}
                        name={'adresse_stationnement'}
                        value={'adresse_stationnement'}
                        label={'Adresse et/ou lieu de stationnement'}
                        className={'small'}
                        onChange={handleCheckChange}
                        checked={isItemChecked('adresse_stationnement')}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        component={Radio}
                        type={'checkbox'}
                        name={'dates_naissance'}
                        value={'dates_naissance'}
                        label={'Date(s) de naissance'}
                        className={'small'}
                        onChange={handleCheckChange}
                        checked={isItemChecked('dates_naissance')}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        component={Radio}
                        type={'checkbox'}
                        name={'permis_conduire'}
                        value={'permis_conduire'}
                        label={'Permis de conduire'}
                        className={'small'}
                        onChange={handleCheckChange}
                        checked={isItemChecked('permis_conduire')}
                    />
                </div>
                {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <Field
                            component={Radio}
                            type={'checkbox'}
                            name={'antecedents_assurance'}
                            value={'antecedents_assurance'}
                            label={'Antécédents d’assurance'}
                            className={'small'}
                            onChange={handleCheckChange}
                            checked={isItemChecked('antecedents_assurance')}
                        />
                    </div>
                }
                <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        component={Radio}
                        type={'checkbox'}
                        name={'sinistres'}
                        value={'sinistres'}
                        label={'Sinistres'}
                        className={'small'}
                        onChange={handleCheckChange}
                        checked={isItemChecked('sinistres')}
                    />
                </div>
                {_.get(data, 'DemandeTarif[Police][MotifAvenant]') !== '10' &&
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <Field
                            component={Radio}
                            type={'checkbox'}
                            name={'aggravations'}
                            value={'aggravations'}
                            label={'Aggravations'}
                            className={'small'}
                            onChange={handleCheckChange}
                            checked={isItemChecked('aggravations')}
                        />
                    </div>
                }
            </div>
            <button
                className={`btn btn-primary btn-arrow mt-4`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};

export default ChoixQuestions;
