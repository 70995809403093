import tchat from '@amo/core/assets/images/icons/FAQ.png';
import _ from 'lodash';
import moment from 'moment';

const RecapPersonne = (props) => {
    const { data, goTo } = props;
    const IndexSouscripteur =
        _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]') === 'true' ||
        _.get(data, 'DemandeTarif[ListePersonnes][1][Civilite]') === '3'
            ? 1
            : 0;

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2'}>
                <div className={'p-3 bg-white rounded border'}>
                    <div className={'row'}>
                        <div className="col-2">
                            <img src={tchat} alt="Informations souscripteur" className={'pt-2'} />
                        </div>

                        <div className="col-10 text-start">
                            <p className={'f-16 text-dark text-start fw-bold'}>
                                Vos informations{' '}
                            </p>
                            <small className={'d-block f-13 text-dark fw-bold'}>
                                {`${
                                    _.get(data, `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Prenom]`) || ''
                                } ${_.get(data, `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Nom]`)}`}
                            </small>
                            <small className={'d-block f-13'}>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Email]`)}
                            </small>
                            <small className={'d-block f-13'}>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][TelFixe]`)}
                            </small>
                            <small className={'d-block f-13'}>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Adresse1]`)}
                            </small>
                            {_.get(data, `DemandeTarif[ListePersonnes][0][Adresse2]`) && (
                                <small className={'d-block f-13'}>
                                    {_.get(data, `DemandeTarif[ListePersonnes][0][Adresse1]`)}
                                </small>
                            )}
                            <small className={'d-block f-13'}>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][CP]`)}{' '}
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Ville]`)}
                            </small>
                            <br />
                            <small className={'d-block f-13 text-dark fw-bold'}>Conducteur :</small>
                            <small className={'d-block f-13'}>
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Prenom]`)}{' '}
                                {_.get(data, `DemandeTarif[ListePersonnes][0][Nom]`)}
                            </small>
                            {_.map(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis, i) => {
                                if (permis?.TypePermis) {
                                    return (
                                        <small className={'d-block f-13'} key={i}>
                                            Permis {permis.TypePermis} (
                                            {moment(permis.DatePermis, 'DD/MM/YYYY').format('L')})
                                        </small>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecapPersonne;
