import warning from '@amo/core/assets/images/icons/warning.svg';
import { Question } from '@amo/core/components/containers';
import { DateField, Select } from '@amo/core/components/forms';
import { maxDateToday, minDate36Mois } from '@amo/core/utils/validateField.js';
import { nbMonthList } from '@amo/core/utils/valuesSelect.js';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';

export const InformationsSuspensionPermis = (props) => {
    const { nextQuestion, data, changeValue } = props;
    const listNbMois = nbMonthList(61);

    const isNotValid = () => {
        const date = _.get(data, 'DemandeTarif[Antecedents][DateSuspensionPermis]');

        return (
            maxDateToday(date) ||
            !moment(date, 'DD/MM/YYYY', true).isValid() ||
            !_.get(data, 'DemandeTarif[Antecedents][NbMoisSuspensionPermis]') ||
            _.get(data, 'DemandeTarif[Antecedents][NbMoisSuspensionPermis]') > 60
        );
    };

    return (
        <Question
            icon={warning}
            title={
                'Indiquez <b>la date de suspension</b><br/> de votre permis et le <b>nombre <br/> de mois de suspension</b>'
            }
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-sm-6 col-12 my-2'}>
                    <Field
                        name={`DemandeTarif[Antecedents][DateSuspensionPermis]`}
                        label={'Permis suspendu le'}
                        component={DateField}
                        placeholder={'Exemple : 22/03/2020'}
                        validate={[maxDateToday, minDate36Mois]}
                    />
                </div>
            </div>
            <div className={'row justify-content-center'}>
                <div className={'col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        label={'Nombre de mois de suspension'}
                        name={'DemandeTarif[Antecedents][NbMoisSuspensionPermis]'}
                        component={Select}
                        onChange={() => changeValue('DemandeTarif[Antecedents][NbMoisSuspensionPermis]', '')}
                    >
                        <option value={''}>Nombre de mois de suspension</option>
                        {listNbMois.map((item, index) => {
                            return (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            );
                        })}
                    </Field>
                </div>
            </div>
            <button
                className={`btn btn-primary btn-arrow mt-4 ${isNotValid() && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
