import tchat from '@amo/core/assets/images/AMO_Icons/Service-client.svg';
import { Question } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import { normalizeTel } from '@amo/core/utils/normalize.js';
import { validatePhone } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { Field } from 'redux-form';

const NumeroTel = (props) => {
    const { data, changeValue, nextQuestion, postFill } = props;
    const onClickNext = () => {
        postFill({ getTarif: true });
    };

    return (
        <Question icon={tchat} title={'Est-ce que ce <b>numéro de téléphone</b> est toujours le bon&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Text}
                        type={'tel'}
                        typeFormat={'tel'}
                        name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                        placeholder={'Exemple : 02 38 53 02 38'}
                        validate={validatePhone}
                    />
                    <small className={'text-justify d-block mt-1'}>
                        Si ce numéro de téléphone n'est plus correct, merci de renseigner votre nouveau numéro.
                    </small>
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[ListePersonnes][0][TelPortable]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>

            {/*<button
                className={`btn btn-primary btn-arrow mt-4 ${!_.get(data, 'DemandeTarif[ListePersonnes][0][TelPortable]') && 'disabled'}`}
                onClick={() => (loadingPostFill ? null : onClickNext())}
            >
                {loadingPostFill ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                ) : (
                    'Comparer les tarifs'
                )}
            </button>*/}
        </Question>
    );
};

export default NumeroTel;
