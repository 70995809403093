import fingers from '@amo/core/assets/images/icons/fingers.png';

const EnvoiPieces = (props) => {
    const { documentsUrl, urlPdfContrat } = props;

    return (
        <div id={'etape-documents'}>
            <div className={'row justify-content-center align-items-center mt-5'}>
                <div className={'col-lg-3 offset-lg-1 col-md-6 col-sm-16 col-6 my-2'}>
                    <img src={fingers} alt={'Confirmation devis'} className={'img-fluid'} />
                </div>
                <div className={'col-lg-7 col-md-6 col-sm-16 col-12 my-2 text-lg-start text-dark text-center'}>
                    <h2 className={'my-4 f-22 fw-bold'}>
                        Votre avenant a été validé et un lien de signature électronique vous a été envoyé !
                    </h2>
                    <a href={urlPdfContrat} target={'_blank'} className={`btn btn-secondary btn-smaller btn-arrow me-2`} rel="noreferrer">
                        Télécharger mon contrat
                    </a>
                    {/*<a href={documentsUrl} target={'_blank'} className={`btn btn-primary btn-smaller btn-arrow`} rel="noreferrer">*/}
                    {/*    Envoyer vos documents*/}
                    {/*</a>*/}
                </div>
            </div>
        </div>
    );
};

export default EnvoiPieces;
