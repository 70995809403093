import Avenant from './Step/Avenant/Avenant.jsx';
import Besoins from './Step/Besoins/Besoins';
import Coordonnees from './Step/Coordonnees/Coordonnees.jsx';
import Antecedents from './Step/Antecedents/Antecedents';
import EnvoiPiecesContainer from './Step/EnvoiPieces/EnvoiPiecesContainer';
import InformationsSouscripteurContainer from './Step/InformationsSouscripteur/InformationsSouscripteurContainer';
import RecapitulatifContainer from './Step/Recapitulatif/RecapitulatifContainer';
import TarifContainer from './Step/Tarif/TarifContainer';
import ValidationContainer from './Step/Validation/ValidationContainer';
import ConfirmationDevisContainer from "./Step/ConfirmationDevis/ConfirmationDevisContainer.js";

let step = 0;

const RouteStep = [
    {
        step: step++,
        slug: 'votre-avenant',
        title: 'Votre avenant',
        main: ({ props, other }) => <Avenant {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-coordonnees',
        title: 'Vos coordonnées',
        main: ({ props, other }) => <Coordonnees {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-antecedents',
        title: 'Vos antécédents',
        main: ({ props, other }) => <Antecedents {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-besoins',
        title: 'Vos besoins',
        main: ({ props, other }) => <Besoins {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'votre-tarif',
        title: 'Votre tarif',
        main: ({ props, other }) => <TarifContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-infos',
        title: 'Vos informations',
        main: ({ props, other }) => <InformationsSouscripteurContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'recapitulatif',
        title: 'Votre récapitulatif',
        main: ({ props, other }) => <RecapitulatifContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'validation',
        title: 'Validation',
        main: ({ props, other }) => <ValidationContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'envoi-des-pieces',
        title: 'Envoi des pièces',
        main: ({ props, other }) => <EnvoiPiecesContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'confirmation-devis',
        title: 'Confirmation de la proposition',
        main: ({ props, other }) => <ConfirmationDevisContainer {...props} {...other} />,
    },
];

export default RouteStep;
