import voiture from '@amo/core/assets/images/AMO_Icons/Voiture.svg';
import { Question } from '@amo/core/components/containers';
import { Select } from '@amo/core/components/forms';
import { nbMonthList } from '@amo/core/utils/valuesSelect.js';
import _ from 'lodash';
import { Field } from 'redux-form';

export const InformationsAssuranceAuto = (props) => {
    const { nextQuestion, data, antecedentsSur3ans, changeValue } = props;
    const max = antecedentsSur3ans ? 36 : 24;
    const listNbMois = antecedentsSur3ans ? nbMonthList(37) : nbMonthList(25);

    return (
        <Question
            icon={voiture}
            title={`Indiquez le <b>nombre de mois d'assurance auto</b> au cours des ${max} derniers mois`}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-sm-12 col-12 my-2'}>
                    <Field
                        name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'}
                        component={Select}
                        onChange={() => changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', '')}
                    >
                        <option value={''}>Nombre de mois d'assurance</option>
                        {listNbMois.map((item, index) => {
                            return (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            );
                        })}
                    </Field>
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    (!_.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]') ||
                        _.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]') > max) &&
                    'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
