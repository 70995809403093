import Parking from '@amo/core/assets/images/AMO_Icons/Parking.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

const IdentiqueStationnement = (props) => {
    const { nextQuestion, data, changeValue } = props;

    return (
        <Question
            icon={Parking}
            title={"Cette adresse est également l'adresse <b>du lieu de stationnement</b> &nbsp;?"}
            position={'bottom'}
        >
            <div className={'row justify-content-center'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'sameStationnement'}
                        value={'1'}
                        label={'Oui'}
                        onClick={() => {
                            changeValue('DemandeTarif[Vehicule][CodePostalGarage]', _.get(data, 'DemandeTarif[ListePersonnes][0][CP]'));
                            changeValue('DemandeTarif[Vehicule][VilleGarage]', _.get(data, 'DemandeTarif[ListePersonnes][0][Ville]'));
                            nextQuestion(300);
                        }}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'sameStationnement'}
                        value={'0'}
                        label={'Non'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${!_.get(data, 'sameStationnement') && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};

export default IdentiqueStationnement;
