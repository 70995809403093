import { getStepBySlug } from '../../../../utils/function';
import { FILL } from '../../../actions/app/fill/fill.actions';
import { setInit } from '../../../actions/app/init/init.actions';
import { POST_SAVE_DEVIS, SAVE_DEVIS } from '../../../actions/app/saveDevis/saveDevis.actions';
import { setError, setLoader, setStep } from '../../../actions/app/ui/ui.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';
import { formatDataForApi } from '../fill/formatDataForApi';

export const saveDevisMiddleware = (dispatch) => (next) => (action) => {
    next(action);

    const { payload } = action;

    switch (action.type) {
        case POST_SAVE_DEVIS:
            const body = formatDataForApi(payload.body);

            next([
                apiRequest({
                    body,
                    method: 'POST',
                    url: payload.data,
                    entity: SAVE_DEVIS,
                    otherData: payload.meta.otherData,
                }),
                setLoader({ state: true, entity: SAVE_DEVIS }),
            ]);

            break;

        case `${SAVE_DEVIS} ${API_SUCCESS}`:
            const { devisOrSubscribe, step } = payload.meta.otherData;

            let nextActions = [
                setInit({ init: payload.data }),
                setError({ state: false, entity: SAVE_DEVIS }),
                setLoader({ state: false, entity: SAVE_DEVIS }),
            ];

            if (devisOrSubscribe === 'subscribe') {
                nextActions.push(setStep({ step: step + 1 }));
            } else if (devisOrSubscribe === 'devis') {
                nextActions.push(setStep({ step: getStepBySlug('confirmation-devis') }));
            }

            window.scroll(0, 0);
            next(nextActions);
            break;

        case `${SAVE_DEVIS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: FILL,
                    html: action.payload.data.response.data.error,
                    title: 'Attention !',
                    icon: 'error',
                }),
                setError({ state: true, entity: SAVE_DEVIS }),
                setLoader({ state: false, entity: SAVE_DEVIS }),
            ]);
            break;

        default:
            return null;
    }
};
