import {DommagesTousAccidents, IndemAccidentResponsable, Remorquage, VolIncendie} from '@amo/core/components/questions';

const Besoins = (props) => {
    const { id } = props;

    return (
        <div id={id}>
            <VolIncendie {...props} />
            <DommagesTousAccidents {...props} />
            <Remorquage {...props} />
            <IndemAccidentResponsable {...props} />
        </div>
    );
};

export default Besoins;
