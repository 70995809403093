import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getStepBySlug} from "../../../../../../utils/function.js";
import tchat from "@amo/core/assets/images/AMO_Icons/Service-client.svg";

const ClientTelephone = (props) => {
    const { nextQuestion, data, goTo, source } = props;

    const onClickNext = (clientTelephone) => {
        if((source === 'conseiller' || source === 'courtier' )&& clientTelephone === '0'){
            goTo({ step: getStepBySlug('votre-tarif'), question: 0 })
        } else {
            nextQuestion(300)
        }
    };

    return (
        <Question
            icon={tchat}
            title={"Est-ce que vous avez <b>le client au téléphone</b>&nbsp;?"}
            position={'bottom'}
        >
            <div className={'row justify-content-center'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'clientTelephone'}
                        value={'1'}
                        label={'Oui'}
                        onClick={(e) => onClickNext(e.target.value)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'clientTelephone'}
                        value={'0'}
                        label={'Non'}
                        onClick={(e) => onClickNext(e.target.value)}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${!_.get(data, 'clientTelephone') && 'disabled'}`}
                onClick={() => onClickNext(_.get(data, 'clientTelephone'))}>
                Continuer
            </button>
        </Question>
    );
};

export default ClientTelephone;
