import { API_URL_WS_FORMULAIRE } from '@amo/core/constants/constants.js';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchContratStatut } from '../../../../../redux/actions/app/checkContratStatut/checkContratStatut.actions';
import { getToken } from '../../../../../redux/selectors/init/init.selectors';
import Validation from './Validation';

const mapStateToProps = (state) => {
    return {
        query: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
    };
};

const mapDispatchToProps = {
    fetchContratStatut,
};

const ValidationContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Validation);

export default ValidationContainer;
