import { API_URL_WS_FORMULAIRE, APP_URL_APPLICATION } from '@amo/core/constants/constants.js';
import { getVehicleHolderName } from '@amo/core/utils/functions';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { POST_PAY, postPay } from '../../../../../redux/actions/app/pay/pay.action';
import { postReloadTarif } from '../../../../../redux/actions/app/tarif/tarif.actions';
import { getFractionnementPoliceDemandeTarifInit, getToken } from '../../../../../redux/selectors/init/init.selectors';
import {
    getInfoCodePromo,
    getListeFormulesTarif,
    isLoadedTarif,
} from '../../../../../redux/selectors/tarif/tarif.selectors';
import {
    getLoadingEntity,
    getPrixTotal,
    getPrixTotalAnnuel,
    getPrixTotalAnnuelPromo,
    getPrixTotalPromo,
} from '../../../../../redux/selectors/ui/ui.selectors';
import { getVehicule } from '../../../../../redux/selectors/vehicule/vehicule.selectors';
import Recapitulatif from './Recapitulatif';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis-avenant');
    const titulaireCarteGriseSo = selector(state, 'titulaireCarteGrise') === '2';
    const datenaissanceCond = selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]');
    const mineur =
        datenaissanceCond &&
        moment.isMoment(datenaissanceCond) &&
        moment(datenaissanceCond).add(18, 'years') > moment();

    let nomPrenomTitulaire =
        selector(state, 'DemandeTarif[ListePersonnes][0][Nom]') +
        ' ' +
        selector(state, 'DemandeTarif[ListePersonnes][0][Prenom]');
    if (titulaireCarteGriseSo) {
        nomPrenomTitulaire = selector(state, 'DemandeTarif[ListePersonnes][1][Nom]');
    } else if (mineur) {
        nomPrenomTitulaire =
            selector(state, 'DemandeTarif[ListePersonnes][1][Nom]') +
            ' ' +
            selector(state, 'DemandeTarif[ListePersonnes][1][Prenom]');
    }

    return {
        vehicule: getVehicule(state),
        formules: getListeFormulesTarif(state),
        fractionnementChoisi: getFractionnementPoliceDemandeTarifInit(state),
        query: `${API_URL_WS_FORMULAIRE}/pay/${getToken(state)}`,
        url_pay: {
            token: getToken(state),
            url_return: `${APP_URL_APPLICATION}`,
        },
        payLoaded: getLoadingEntity(state, POST_PAY),
        nomTitulaireCg: getVehicleHolderName(
            selector(state, 'DemandeTarif[ListePersonnes]'),
            selector(state, 'titulaireCarteGrise'),
        ),
        nomPrenom: nomPrenomTitulaire,
        isLoadedTarif: isLoadedTarif(state),
        queryTarif: `${API_URL_WS_FORMULAIRE}/get_tarif/${getToken(state)}`,
        prixTotalMensuel: getPrixTotal(state),
        prixTotalMensuelPromo: getPrixTotalPromo(state),
        prixTotalAnnuel: getPrixTotalAnnuel(state),
        prixTotalAnnuelPromo: getPrixTotalAnnuelPromo(state),
        codePromo: getInfoCodePromo(state),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            postPay,
            postReloadTarif,
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { url_pay, query } = stateProps;
    const { postPay } = dispatchProps;
    const { data } = ownProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        postPaySubmit: () => postPay({ form: data, query: query, url_pay }),
    };
};

const RecapitulatifContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(Recapitulatif);

export default RecapitulatifContainer;
