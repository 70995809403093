import { useRef, useState } from 'react';
import { Field } from 'redux-form';
import { getRaisonSocialeBySiren, listFormeJuridique, normalizeDecimal } from '../../../utils';
import { Select, Text } from '../../forms';

export const InformationEntreprise = (props) => {
    const { changeValue, needAdresse = false } = props;
    const [isFirstFetch, setIsFirstFetch] = useState(true);
    const [loadingSIREN, setLoadingSIREN] = useState(false);
    const [errorSIREN, setErrorSIREN] = useState(false);
    const [needManualReason, setNeedManualReason] = useState(false); // [TODO] : needManualReason is always false, so the following condition is always false too : !_.get(props.data, 'DemandeTarif[ListePersonnes][1][Nom]') && errorSIREN
    const inputSIREN = useRef(null);

    const fetchGetSociety = async (value) => {
        setLoadingSIREN(true);

        try {
            let society = await getRaisonSocialeBySiren(value);
            changeValue('DemandeTarif[ListePersonnes][1][Nom]', society.denomination);

            if (needAdresse) {
                const adresse = society.adresse

                // adresse
                changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', (adresse?.numVoie + ' ' + adresse?.typeVoie + ' ' + adresse?.voie ).replace('undefined', '').trim());
                changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', '');
                changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', '');
                changeValue('DemandeTarif[ListePersonnes][0][CP]', adresse.codePostal);
                changeValue('DemandeTarif[ListePersonnes][0][Ville]', adresse.commune);

            }

            setErrorSIREN(false);
        } catch (e) {
            if (e.response.status === 500) {
                setNeedManualReason(true);
            } else {
                setErrorSIREN(e.response.data.message);
            }
        }

        setIsFirstFetch(false)
        setLoadingSIREN(false);
    };
    
    const whenSIRENChange = () => {
        changeValue('DemandeTarif[ListePersonnes][1][Nom]', '');
        if (needAdresse) {
            // adresse
            changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', '');
            changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', '');
            changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', '');
            changeValue('DemandeTarif[ListePersonnes][0][CP]', '');
            changeValue('DemandeTarif[ListePersonnes][0][Ville]', '');
        }
        setIsFirstFetch(true);
    }

    return (
        <>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                    <Field
                        label={'N° de SIREN de votre société (9 chiffres. Exemple : 123456789)'}
                        component={Text}
                        type={'tel'}
                        name={'DemandeTarif[ListePersonnes][1][IdSociety]'}
                        normalize={normalizeDecimal}
                        onChange={whenSIRENChange}
                        ref={inputSIREN}
                        required
                    />

                    {errorSIREN && (
                        <div className={'alert-danger alert mb-0'}>
                            {errorSIREN}
                        </div>
                    )}

                    {needManualReason && (
                        <div className={'alert-danger alert mb-0'}>
                            Nous n’arrivons pas à récupérer les informations de votre société depuis l’INPI. Merci de compléter les informations suivantes manuellement.
                        </div>
                    )}

                    {(!errorSIREN && !isFirstFetch) && (
                        <div className={'alert-success alert mb-0'}>
                            Les données de la société ont bien été récupérées depuis l’INPI. Merci de vérifier les
                            informations pré-remplies. Si les données ne correspondent pas à votre société, vérifier le
                            numéro de SIREN renseigné et recommencer
                        </div>
                    )}

                    <span
                        className="btn btn-secondary btn-smaller mt-2"
                        onClick={() => fetchGetSociety(inputSIREN.current.value)}
                    >
                        {loadingSIREN ? (
                            <div className="spinner-border" role="status" style={{ width: 19, height: 19 }}>
                                <span className="sr-only" />
                            </div>
                        ) : (
                            'Je valide mon numéro de SIREN'
                        )}
                    </span>
                </div>
            </div>

            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                    <Field
                        name={`DemandeTarif[ListePersonnes][1][Nom]`}
                        label={`Raison sociale`}
                        component={Text}
                        type={'text'}
                        required
                    />
                </div>
            </div>

            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                    <Field
                        label={'Statut juridique de votre entreprise'}
                        name={'DemandeTarif[ListePersonnes][1][LegalStatus]'}
                        component={Select}
                        onChange={() => changeValue('DemandeTarif[ListePersonnes][1][LegalStatus]', '')}
                        required
                    >
                        <option value={''}>Sélectionnez le statut juridique de votre entreprise</option>
                        {listFormeJuridique.map((item, index) => {
                            return (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            );
                        })}
                    </Field>
                </div>
            </div>
        </>
    );
};
