import { connect } from 'react-redux';
import {
    getIBANDemandeContratInit,
    getNomTitulaireDemandeContratInit,
} from '../../../../../../redux/selectors/init/init.selectors.js';
import Iban from './Iban';

const mapStateToProps = (state) => {
    return {
        iban: getIBANDemandeContratInit(state),
        titulaire: getNomTitulaireDemandeContratInit(state),
    };
};

const IbanContainer = connect(mapStateToProps)(Iban);

export default IbanContainer;
