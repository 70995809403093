import { LargeSpinner } from '@amo/core/components/loaders';
import { useEffect } from 'react';

const Validation = (props) => {
    const { query, fetchContratStatut } = props;

    useEffect(() => {
        const timer = setTimeout(() => fetchContratStatut({ query }), 10000);
        return () => clearTimeout(timer);
    });

    return <LargeSpinner message={'Votre souscription est en cours de validation....'} />;
};

export default Validation;
