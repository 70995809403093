import protection from '@amo/core/assets/images/AMO_Icons/AboutProtection.svg';
import info from '@amo/core/assets/images/icons/info.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

export const Assure3DerniersMois = (props) => {
    const { nextQuestion, data } = props;

    return (
        <Question icon={protection} title={'Ce véhicule a-t-il été assuré au cours des <b>3 derniers mois</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][Assure3DerniersMois]'}
                        value={'1'}
                        label={'Oui'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][Assure3DerniersMois]'}
                        value={'0'}
                        label={'Non'}
                    />
                </div>
            </div>

            <BulleAide
                icon={info}
                title={'Attention'}
                text={
                    "Lorsque vous êtes assuré depuis plus d’un an, plus de préavis de résiliation à respecter avec la loi Hamon.<br/>Nous nous occupons de résilier votre contrat auprès de votre assureur actuel."
                }
            />

            {_.get(data, 'DemandeTarif[Vehicule][PossessionPlusDe3Mois]') === '1' && _.get(data, 'DemandeTarif[Vehicule][Assure3DerniersMois]') === '0' && (
                <BulleAide
                    icon={info}
                    title={'Attention'}
                    text={
                        "Le véhicule n'a pas été assuré sur les trois derniers mois et seule la <b>Formule Essentielle</b> sera proposée."
                    }
                />
            )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[Vehicule][Assure3DerniersMois]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};