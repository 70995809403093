import warning from '@amo/core/assets/images/AMO_Icons/Sinistres.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

export const Sinistres = (props) => {
    const { nextQuestion, data, changeValue, antecedentsSur3ans } = props;

    return (
        <Question
            icon={warning}
            title={'Avez-vous eu <b>des sinistres</b><br/><b>auto</b> et/ou <b>moto</b>&nbsp;?'}
            subtitle={`Au cours des ${antecedentsSur3ans ? 3 : 2} dernières années ...`}
            position={'bottom'}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'sinistre_auto'}
                        value={'1'}
                        label={'Oui'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'sinistre_auto'}
                        value={'0'}
                        label={'Non'}
                        onClick={() => {
                            changeValue('DemandeTarif[Antecedents][ListeSinistres]', []);
                            nextQuestion(300);
                        }}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${!_.get(data, 'sinistre_auto') && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
