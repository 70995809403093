import { API_URL_WS_FORMULAIRE } from '@amo/core/constants/constants.js';

export const INIT = '[Init]';

export const SET_INIT = `${INIT} Set`;
export const POST_INIT = `${INIT} Post`;

export const postInit = ({ token, key, origin }) => ({
    type: POST_INIT,
    payload: {
        data: `${API_URL_WS_FORMULAIRE}/init`,
        body: { token, key, origin },
    },
});

export const setInit = ({ init }) => ({
    type: SET_INIT,
    payload: {
        data: init,
    },
});
