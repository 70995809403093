import _ from 'lodash';
import ChangementAdresse from './ChangementAdresse/ChangementAdresse.jsx';
import GoToCoordonnees from './GoToCoordonnees/GoToCoordonnees.jsx';
import MotifAvenant from './MotifAvenant/MotifAvenant.jsx';
import ChangementUsage from "./ChangementUsage/ChangementUsage.jsx";
import ChangementVehicule from "./ChangementVehicule/ChangementVehicule.jsx";
import {useEffect, useState} from "react";
import moment from "moment";
import ChangementModifContrat from "./ChangementModifContrat/ChangementModifContrat.jsx";
import ChangementConducteur from "./ChangementConducteur/ChangementConducteur.jsx";
import {SinistresAutoMoto} from "@amo/core/components/questions";
import TypeVehicule from "./TypeVehicule/TypeVehicule.jsx";
import MarqueVehiculeContainer from "./MarqueVehicule/MarqueVehiculeContainer.js";
import CylindreeVehiculeContainer from "./CylindreeVehicule/CylindreeVehiculeContainer.js";
import ModeleVehiculeContainer from "./ModeleVehicule/ModeleVehiculeContainer.js";
import VehiculeManquant from "./VehiculeManquant/VehiculeManquant.jsx";
import DateMec from "./ChangementVehicule/DateMEC/DateMEC.jsx";
import InformationsVehicule from "./InformationsVehicule/InformationsVehicule.jsx";
import {getInformationVehicule} from "@amo/core/utils/index.js";
import DateAchat from "./ChangementVehicule/DateAchat/DateAchat.jsx";

const Avenant = (props) => {
    const { data, id, dateMEC, changeValue, immat, valeurVehicule, hasEmptyNatureSinistre, initialValues, vehicule } = props
    const motifAvenant = _.get(data, 'DemandeTarif[Police][MotifAvenant]')
    const antecedentsSur3ans = _.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]') === '36'


    const [noVehicule] = useState(
        initialValues?.marqueVehicule === undefined ||
        initialValues?.cylindreeVehicule === undefined ||
        initialValues?.modeleVehicule === undefined ||
        !_.get(initialValues, 'DemandeTarif[Vehicule][IdentifiantSRA]') ||
        vehicule?.sra === undefined
    );

    useEffect(() => {
        if (motifAvenant !== '1' && motifAvenant !== undefined) {
            changeValue('DemandeTarif[Vehicule][DateMEC]', moment(dateMEC, 'DD/MM/YYYY'));
            changeValue('DemandeTarif[Vehicule][Immatriculation]', immat)
            changeValue('DemandeTarif[Vehicule][ValeurVehicule]', valeurVehicule)
        }
    }, [motifAvenant]);

    return (
        <div id={id}>
            <MotifAvenant {...props} />
            {
                noVehicule && <>
                    <VehiculeManquant {...props} />
                    <TypeVehicule {...props} />
                    {data?.typeVehicule && noVehicule && <MarqueVehiculeContainer {...props} />}
                    {data?.marqueVehicule && noVehicule && <CylindreeVehiculeContainer {...props} />}
                    {data?.cylindreeVehicule && noVehicule && <ModeleVehiculeContainer {...props} />}
                    {vehicule?.sra && getInformationVehicule(vehicule) !== '' && <InformationsVehicule {...props} /> }
                    <DateMec {...props} />
                    <DateAchat {...props} />
                </>
            }

            {motifAvenant === '3' && <ChangementAdresse {...props} />}
            {motifAvenant === '9' || motifAvenant === '29' && <ChangementUsage {...props} />}
            {motifAvenant === '1' && <ChangementVehicule {...props} />}
            {(motifAvenant === '8' || motifAvenant === '10' || motifAvenant === '11') && <ChangementModifContrat {...props} />}
            {motifAvenant === '5' && <ChangementConducteur {...props} />}
            {hasEmptyNatureSinistre && <SinistresAutoMoto antecedentsSur3ans={antecedentsSur3ans} canDelete title={'Merci de repréciser la nature de votre sinistre'}{...props} /> }

            <GoToCoordonnees {...props} />
        </div>
    );
};

export default Avenant;
