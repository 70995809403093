import { SET_INIT } from '../../actions/app/init/init.actions';

export const initReducer = (init = {}, action) => {
    const { payload } = action;

    switch (action.type) {
        case SET_INIT:
            return payload.data;

        default:
            return init;
    }
};
