import Sinistres from '@amo/core/assets/images/AMO_Icons/Sinistres.svg';
import { Question } from '@amo/core/components/containers';
import { AddSinistre } from '@amo/core/components/group-form';
import _ from 'lodash';
import moment from 'moment';
import { FieldArray } from 'redux-form';

export const SinistresAutoMoto = (props) => {
    const { nextQuestion, data, antecedentsSur3ans, title, canDelete, changeValue } = props;

    const isNotValid = () => {
        let notValid = false;
        _.forEach(_.get(data, `DemandeTarif[Antecedents][ListeSinistres]`), function (k, v) {
            if (
                !_.get(data, `DemandeTarif[Antecedents][ListeSinistres][${v}][NatureSinistre]`) ||
                !_.get(data, `DemandeTarif[Antecedents][ListeSinistres][${v}][VehiculeSinistre]`) ||
                !moment(
                    _.get(data, `DemandeTarif[Antecedents][ListeSinistres][${v}][DateSinistre]`),
                    'DD/MM/YYYY',
                    true,
                ).isValid()
            ) {
                notValid = true;
            }

            if (
                _.get(data, `DemandeTarif[Antecedents][ListeSinistres][${v}][NatureSinistre]`) &&
                _.includes(
                    ['CIR', 'STA'],
                    _.get(data, `DemandeTarif[Antecedents][ListeSinistres][${v}][NatureSinistre]`),
                ) &&
                !_.get(data, `DemandeTarif[Antecedents][ListeSinistres][${v}][Responsabilite]`) &&
                !_.get(data, `DemandeTarif[Antecedents][ListeSinistres][${v}][TypeSinistre]`)
            ) {
                notValid = true;
            }
        });

        return notValid;
    };
    const onClickNext = () => {
        changeValue('DemandeTarif[Antecedents][ListeSinistres]', [])
        nextQuestion(0)
    };


    return (
        <Question
            icon={Sinistres}
            title={title ? title : 'Avez-vous eu des sinistres<br/>auto et/ou moto&nbsp;?'}
            subtitle={`Au cours des ${antecedentsSur3ans ? 3 : 2} dernières années ...`}
            position={'bottom'}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-12 my-2'}>
                    <FieldArray
                        name={'DemandeTarif[Antecedents][ListeSinistres]'}
                        component={AddSinistre}
                        sinistres={_.get(data, 'DemandeTarif[Antecedents][ListeSinistres]')}
                        antecedentsSur3ans={antecedentsSur3ans}
                    />
                </div>
                {canDelete &&
                    <div className={'col-12 my-2'}>
                        <button
                            className={`btn btn-primary btn-arrow mt-4`}
                            onClick={() => onClickNext()}
                        >
                            Je n'ai pas de sinistre
                        </button>
                    </div>
                }
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${isNotValid() && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
