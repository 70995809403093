import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { formValueSelector } from 'redux-form';
import {
    FETCH_MARQUES_VEHICULIER,
    fetchMarquesVehicule,
} from '../../../../../../redux/actions/app/vehiculier/vehiculier.actions.js';
import { getLoadingEntity, getMarquesVehiculeUi } from '../../../../../../redux/selectors/ui/ui.selectors.js';
import MarqueVehicule from './MarqueVehicule.jsx';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis-avenant');

    return {
        type: selector(state, `typeVehicule`),
        marque: selector(state, `marqueVehicule`),
        marques: getMarquesVehiculeUi(state),
        loading: getLoadingEntity(state, FETCH_MARQUES_VEHICULIER),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchMarquesVehicule: ({ type }) => fetchMarquesVehicule({ type }),
        },
        dispatch,
    );

const MarqueVehiculeContainer = compose(connect(mapStateToProps, mapDispatchToProps))(MarqueVehicule);

export default MarqueVehiculeContainer;
