import { APP_URL_APPLICATION } from '@amo/core/constants/constants.js';
import { getStepBySlug } from '../../../../utils/function';
import { FILL } from '../../../actions/app/fill/fill.actions';
import { CODEPROMO, POST_TARIF_RELOAD, setTarif, TARIF, TARIF_RELOAD } from '../../../actions/app/tarif/tarif.actions';
import { setError, setErrorTarif, setLoader, setStep } from '../../../actions/app/ui/ui.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';
import { formatDataForApi } from '../fill/formatDataForApi';
import * as Sentry from '@sentry/react';

export const tarifMiddleware = () => (next) => (action) => {
    next(action);

    const { payload } = action;
    let body = {};
    let nextActions;

    switch (action.type) {
        case POST_TARIF_RELOAD:
            if (payload.body) {
                body = formatDataForApi(payload.body);
            }

            next([
                apiRequest({
                    body: body,
                    method: 'POST',
                    url: payload.data,
                    entity: TARIF_RELOAD,
                    otherData: { codePromo: payload.meta.otherData.codePromo, source: payload.meta.otherData.source },
                }),
                setLoader({ state: true, entity: payload.meta.otherData.codePromo ? CODEPROMO : TARIF }),
            ]);
            break;

        case `${TARIF_RELOAD} ${API_SUCCESS}`:
            nextActions = [
                setTarif({ data: payload.data.data.Tarif, otherData: payload.meta.otherData }),
                setError({ state: false, entity: action.payload.meta.otherData.codePromo ? CODEPROMO : TARIF }),
                setLoader({ state: false, entity: action.payload.meta.otherData.codePromo ? CODEPROMO : TARIF }),
            ];
            if (payload.data.data.Tarif.FeuTricolore === 'Rouge') {
                nextActions.push(setStep({ step: getStepBySlug('vos-besoins') }));
            }
            next(nextActions);
            break;

        case `${TARIF_RELOAD} ${API_ERROR}`:
            const { source } = payload.meta.otherData;

            next([
                setStep({ step: getStepBySlug('vos-besoins') }),
                setErrorTarif({ data: payload?.data?.response?.data?.error || 'Impossible de calculer le tarif' }),
                setNotification({
                    entity: FILL,
                    html: source === "conseiller"
                        ? (payload?.data?.response?.data?.error
                            ? `<p style="color:#EF2F2FFF">${payload.data.response.data?.error}</p><br/>Si c'est une erreur de saisie, vous pouvez modifier les informations declarées sur les étapes précédentes.`
                            : "<h4 style='color: #004161;'>Les informations que vous avez saisies ne nous permettent pas de vous proposer un tarif en ligne.</h4><br/>Si c'est une erreur de saisie, vous pouvez modifier les informations declarées sur les étapes précédentes.<br/>Vous avez une question ? Vous pouvez nous appeler au 02 47 51 07 07")
                        : "Au vue de la situation de votre contrat, nous sommes dans l'impossibilité de valider en ligne votre demande d'avenant.<br/>Vous avez besoin de modifier votre contrat ? Nos conseillers restent disponibles au 02 47 51 07 07 du lundi au vendredi de 9h à 18H pour répondre à toutes vos questions.",
                    title:
                        payload?.data?.response?.data?.code === 444
                            ? 'Les informations que vous avez saisies ne nous permettent pas de vous proposer un tarif en ligne.'
                            : payload?.data?.response?.data?.error,
                    icon: 'error',
                    confirmButtonText: "J'ai compris",
                    allowOutsideClick: false,
                    successFunction: () => {
                        if (payload?.data?.response?.data?.code === 444) {
                            window.localStorage.clear();
                            window.location.reload();
                            window.location.href = APP_URL_APPLICATION;
                        } else {
                            window.location.reload();
                        }
                    },
                }),
                setError({ state: true, entity: action.payload.meta.otherData.codePromo ? CODEPROMO : TARIF }),
                setLoader({ state: false, entity: action.payload.meta.otherData.codePromo ? CODEPROMO : TARIF }),
            ]);
            break;

        default:
            return null;
    }
};
