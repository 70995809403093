import { connect } from 'react-redux';
import { CODEPROMO } from '../../../../../../redux/actions/app/tarif/tarif.actions';
import { getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import CodePromo from './CodePromo';
import {getSource} from "../../../../../../redux/selectors/init/init.selectors.js";

const mapStateToProps = (state) => {
    return {
        loading: getLoadingEntity(state, CODEPROMO),
        sourceIsConseiller: getSource(state) === 'conseiller'
    };
};

const CodePromoContainer = connect(mapStateToProps)(CodePromo);

export default CodePromoContainer;
