import {
    SET_DEVIS_OR_SUBSCRIBE,
    SET_ERROR,
    SET_INDEX_QUESTION,
    SET_LOADER,
    SET_MAINTENANCE,
    SET_MAX_STEP,
    SET_STEP,
    SET_TARIF_ERROR,
    SET_UTM_SOURCE,
} from '../../actions/app/ui/ui.actions';
import {
    SET_CYLINDREES_VEHICULIER,
    SET_MARQUES_VEHICULIER,
    SET_MODELES_VEHICULIER,
} from '../../actions/app/vehiculier/vehiculier.actions';

const initState = {
    maintenance: false,
    step: 0,
    maxStep: 0,
    question: 0,
    devisOrSubscribe: 'devis',
    loading: {},
    errorTarif: '',
    vehicule: {},
    locationSearch: {},
};

export const uiReducer = (ui = initState, action) => {
    const { payload, meta } = action;

    switch (true) {
        case action.type.includes(SET_LOADER):
            return { ...ui, loading: { ...ui.loading, [meta]: payload } };

        case action.type.includes(SET_ERROR):
            return { ...ui, error: { ...ui.error, [meta]: payload } };

        case action.type.includes(SET_MAINTENANCE):
            return { ...ui, maintenance: payload };

        case action.type.includes(SET_MAX_STEP):
            return { ...ui, maxStep: payload };

        case action.type.includes(SET_STEP):
            return { ...ui, step: payload };

        case action.type.includes(SET_INDEX_QUESTION):
            return { ...ui, question: payload };

        case action.type.includes(SET_DEVIS_OR_SUBSCRIBE):
            return { ...ui, devisOrSubscribe: payload };

        case action.type.includes(SET_TARIF_ERROR):
            return { ...ui, errorTarif: payload };

        case action.type.includes(SET_MARQUES_VEHICULIER):
            return { ...ui, vehicule: { ...ui.vehicule, marques: payload.data } };

        case action.type.includes(SET_CYLINDREES_VEHICULIER):
            return { ...ui, vehicule: { ...ui.vehicule, cylindrees: payload.data } };

        case action.type.includes(SET_MODELES_VEHICULIER):
            return { ...ui, vehicule: { ...ui.vehicule, modeles: payload.data } };

        case action.type.includes(SET_UTM_SOURCE):
            return { ...ui, locationSearch: payload };

        default:
            return ui;
    }
};
