import { LargeSpinner } from '@amo/core/components/loaders';
import * as Sentry from '@sentry/react';
import _ from 'lodash';
import { useEffect } from 'react';
import InitContainer from './Init/InitContainer';

const InitByDevisWs = ({ loaded, postInit, state }) => {
    const logs = [
        {
            libelle: 'form',
            state: state.form?.['devis-avenant']?.values,
        },
        {
            libelle: 'step',
            state: state.ui.step,
        },
        {
            libelle: 'vehicule',
            state: state.vehicule,
        },
        {
            libelle: 'indexQuestion',
            state: state.ui.question,
        },
        {
            libelle: 'init',
            state: state.init,
        },
        {
            libelle: 'loading',
            state: state.ui.loading,
        },
        {
            libelle: 'error',
            state: state.ui.error,
        },
    ];

    _.map(logs, (log) => {
        Sentry.addBreadcrumb({
            category: log.libelle,
            message: JSON.stringify(log.state),
            level: 'debug',
        });
    });

    useEffect(() => {
        postInit();
    }, []);

    if (!loaded) {
        return <LargeSpinner />;
    }

    return <InitContainer />;
};

InitByDevisWs.defaultProps = {
    loading: true,
};

export default InitByDevisWs;
