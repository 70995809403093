import ChoixQuestions from "./ChoixQuestions/ChoixQuestions.jsx";
import {useEffect, useState} from "react";
import TypeVehicule from "../TypeVehicule/TypeVehicule.jsx";
import MarqueVehiculeContainer from "../MarqueVehicule/MarqueVehiculeContainer.js";
import ModeleVehiculeContainer from "../ModeleVehicule/ModeleVehiculeContainer.js";
import CylindreeVehiculeContainer from "../CylindreeVehicule/CylindreeVehiculeContainer.js";
import DateMec from "../ChangementVehicule/DateMEC/DateMEC.jsx";
import {
    Assure3DerniersMois,
    PossedeVehiculePlus3Mois,
    UsageVehicule,
    ValeurVehicule,
    DateNaissance,
    DateNaissanceRepresentantLegal,
    Sinistres,
    SinistresAutoMoto,
    ValeurCrmMoto,
    ValeurCrmAuto,
    AssuranceAuto,
    InformationsAssuranceAuto,
    AssuranceMoto,
    InformationsAssuranceMoto, QuestionImmatriculation, TitulaireCarteGrise
} from "@amo/core/components/questions";
import AdresseActuelleContainer from "../AdresseActuelle/AdresseActuelleContainer.js";
import NouvelleAdresse from "../ChangementAdresse/NouvelleAdresse/NouvelleAdresse.jsx";
import IdentiqueStationnement from "../ChangementAdresse/IdentiqueStationnement/IdentiqueStationnement.jsx";
import _ from "lodash";
import LieuStationnement from "../ChangementAdresse/LieuStationnement/LieuStationnement.jsx";
import moment from "moment";
import ChoixPermis from "../ChangementVehicule/ChoixPermis/ChoixPermis.jsx";
import Aggravations from "./Aggravations/Aggravations.jsx";
import {getInformationVehicule} from "@amo/core/utils/index.js";
import InformationsVehicule from "../InformationsVehicule/InformationsVehicule.jsx";
import DateAchat from "../ChangementVehicule/DateAchat/DateAchat.jsx";

const ChangementModifContrat = (props) => {
    const { id, data, changeValue, initialValues, vehicule } = props;
    const conducteur = _.get(data, 'DemandeTarif[ListePersonnes][0]')
    const [selectedItems, setSelectedItems] = useState({});
    const handleSelectionChange = (newSelection) => {
        setSelectedItems(newSelection);
    };
    const souscripteurIsMineur = (data) => {
        return (
            moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(18, 'years') >=
            moment()
        );
    };
    const souscripteurIsSoc = (data) => {
        return (
            _.get(data, 'DemandeTarif[ListePersonnes][1][Souscripteur]') && _.get(data, 'DemandeTarif[ListePersonnes][1][TypePersonne]') === 'M'
        );
    };

    useEffect(() => {
        if(souscripteurIsMineur(data)) {
            changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'P')
            changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true)
            changeValue('DemandeTarif[ListePersonnes][0][TypePersonne]', 'P')
            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false)
        } else if(!_.isEmpty(conducteur) && !souscripteurIsSoc(data)) {
            changeValue('DemandeTarif[ListePersonnes]', [{...conducteur}])
        }
    }, [_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]')]);


    const antecedentsSur3ans =
        _.get(initialValues, 'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]') === '36' ||
        _.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]') === '36' ||
        _.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '1' ||
        _.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '1' ||
        _.get(data, 'DemandeTarif[Antecedents][ResiliationAssureur]') === '1' ||
        Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]')) > 1 ||
        Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]')) > 1;

    useEffect(() => {
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]', antecedentsSur3ans ? '36' : '24')
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', antecedentsSur3ans ? '36' : '24')
    }, [antecedentsSur3ans]);

    return (
        <div id={id}>
            <ChoixQuestions {...props} onSelectionChange={handleSelectionChange} />
            {selectedItems.vehicule_marque_cylindree_modele &&
                <>
                    <TypeVehicule {...props} />
                    <MarqueVehiculeContainer {...props} />
                    <CylindreeVehiculeContainer {...props} />
                    <ModeleVehiculeContainer {...props} />
                    {getInformationVehicule(vehicule) !== '' && <InformationsVehicule {...props} /> }
                    <DateMec {...props} />
                    <DateAchat {...props} />
                    <QuestionImmatriculation {...props} />
                    <TitulaireCarteGrise {...props} />
                </>
            }
            {selectedItems.date_mise_circulation && <DateMec{...props} />}
            {selectedItems.date_achat && <DateAchat {...props} />}
            {selectedItems.valeur_vehicule && <ValeurVehicule title={'Quelle est <b>la valeur</b> de votre <b>véhicule</b>&nbsp;?'}  {...props} />}
            {selectedItems.client_proprietaire &&
                <>
                    <PossedeVehiculePlus3Mois {...props} />
                    {_.get(data, 'DemandeTarif[Vehicule][PossessionPlusDe3Mois]') === '1' && <Assure3DerniersMois {...props} />}
                </>
            }
            {selectedItems.usage && <UsageVehicule title={'A quelle(s) <b>occasion(s)</b> <br/>utilisez-vous <b>votre véhicule</b>&nbsp;?'} {...props} />}
            {selectedItems.adresse_stationnement &&
                <>
                    <AdresseActuelleContainer {...props} />
                    {data?.changeAdresse === '0' && <NouvelleAdresse {...props} />}
                    <IdentiqueStationnement {...props} />
                    {_.get(data, 'sameStationnement') === '0' && <LieuStationnement {...props} />}
                </>
            }
            {selectedItems.dates_naissance &&
                <>
                    <DateNaissance {...props} />
                    {souscripteurIsMineur(data) && <DateNaissanceRepresentantLegal {...props} />}
                </>
            }
            {selectedItems.permis_conduire && <ChoixPermis {...props} />}
            {selectedItems.antecedents_assurance &&
                <>
                    {_.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                        return _.includes(['AM', 'A1', 'A2', 'A', 'B'], permis?.TypePermis);
                    }) && <ValeurCrmMoto {...props} />}

                    {_.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                        return _.includes(['B'], permis?.TypePermis);
                    }) && <ValeurCrmAuto {...props} />}
                    {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                        _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                            return _.includes(['B'], permis?.TypePermis);
                        }) && <AssuranceAuto {...props} antecedentsSur3ans={antecedentsSur3ans} />}

                    {_.get(data, 'assure_auto') === '1' && (
                        <InformationsAssuranceAuto {...props} antecedentsSur3ans={antecedentsSur3ans} />
                    )}

                    {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                        _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                            return _.includes(['A', 'A1', 'A2', 'B'], permis?.TypePermis);
                        }) && <AssuranceMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />}

                    {_.get(data, 'assure_moto') === '1' && (
                        <InformationsAssuranceMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />
                    )}
                    {(Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]')) > 1 || Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]')) > 1) &&
                        <Aggravations {...props} />
                    }
                </>
            }
            {selectedItems.sinistres &&
                <>
                    <Sinistres {...props} antecedentsSur3ans={antecedentsSur3ans} />

                    {_.get(data, 'sinistre_auto') === '1' && (
                        <SinistresAutoMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />
                    )}
                </>
            }
            {selectedItems.aggravations &&
                <>
                    <Aggravations {...props} />
                    {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                        _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                            return _.includes(['B'], permis?.TypePermis);
                        }) && <AssuranceAuto {...props} antecedentsSur3ans={antecedentsSur3ans} />}

                    {_.get(data, 'assure_auto') === '1' && (
                        <InformationsAssuranceAuto {...props} antecedentsSur3ans={antecedentsSur3ans} />
                    )}

                    {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                        _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                            return _.includes(['A', 'A1', 'A2'], permis?.TypePermis);
                        }) && <AssuranceMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />}

                    {_.get(data, 'assure_moto') === '1' && (
                        <InformationsAssuranceMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />
                    )}
                </>
            }
        </div>
    );
};

export default ChangementModifContrat;
