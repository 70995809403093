import Parking from '@amo/core/assets/images/AMO_Icons/Parking.svg';
import { Question } from '@amo/core/components/containers';
import { PostalCodeCity, Text } from '@amo/core/components/forms';
import { excludeCodePostal } from '@amo/core/utils/validateField.js';
import _ from 'lodash';
import { useState } from 'react';
import { Field } from 'redux-form';
import {CAPAdresseTrottinetteVae} from "@amo/core/components/forms";

const NouvelleAdresse = (props) => {
    const { nextQuestion, data, changeValue } = props;
    const { DemandeTarif } = data;
    const { ListePersonnes = [] } = DemandeTarif;
    const [person] = ListePersonnes;
    const { Adresse1, CP, Ville } = person || {};

    const [addressSelected, setAddressSelected] = useState(Ville ? `${Ville} ${CP}` : null);


    const isNotValid = () => !Adresse1 || !CP || !Ville;

    return (
        <Question icon={Parking} title={'Quelle est votre <b>nouvelle adresse</b>&nbsp;?'} position={'bottom'}>
            <div>
                <div style={{position: 'relative', left: '50%', transform: 'translateX(-24%)'}}>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                            <PostalCodeCity
                                id={'codepostal'}
                                nameVille={'DemandeTarif[ListePersonnes][0][Ville]'}
                                nameCodePostal={'DemandeTarif[ListePersonnes][0][CP]'}
                                validate={excludeCodePostal}
                                placeholder={'Exemple : 75011 PARIS'}
                                changeValue={changeValue}
                                defaultValue={Ville ? `${Ville} ${CP}` : null}
                            />
                        </div>
                    </div>

                    <CAPAdresseTrottinetteVae noLabel changeValue={changeValue} data={data}
                                              setHasSearchAddress={setAddressSelected}/>
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${isNotValid() && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};

export default NouvelleAdresse;
