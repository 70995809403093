import NonInclus from '@amo/core/assets/images/icons/non_inclus.svg';
import { RadioBtn } from '@amo/core/components/forms';
import { showModal } from '@amo/core/utils/functions';
import _ from 'lodash';
import { Field } from 'redux-form';
import getPriceByFractionnementOrCodePromo from '../../utilsTarif/getPriceByFractionnementOrCodePromo';
import './table.scss';

const TableFormule = (props) => {
    const { formules, formuleChoisie, fractionnementChoisi, TextOptions, data, formulePreconisee, codePromo, changeValue } = props;

    const buildLabelFormule = (f) => {
        const price_by_frac_or_code_promo = getPriceByFractionnementOrCodePromo(f, fractionnementChoisi);

        if (codePromo?.Eligible && price_by_frac_or_code_promo.prix > price_by_frac_or_code_promo.prixPromo) {
            return `<span class="title text-dark">${f.LibelleFormule.toUpperCase()}</span>
                    <span class='price'>${price_by_frac_or_code_promo.prixPromo} <small>TTC/${
                        fractionnementChoisi === 'M' ? 'mois' : 'an'
                    }</small>
                    <br/>
                    <span class='f-16 text-decoration-line-through'>${price_by_frac_or_code_promo.prix} <small>TTC/${
                        fractionnementChoisi === 'M' ? 'mois' : 'an'
                    }</small>
                    </span>`;
        } else {
            return `<span class="title text-dark">${f.LibelleFormule.toUpperCase()}</span><span class='price'>${
                price_by_frac_or_code_promo.prix
            } <small>TTC/${fractionnementChoisi === 'M' ? 'mois' : 'an'}</small></span>`;
        }
    };

    return (
        <div className="table-responsive w-100">
            <table className={'table'}>
                <thead>
                    <tr>
                        <th className={''}>
                            <div className={'d-inline-block w-100 text-start py-4 f-24'}>Vos garanties</div>
                        </th>
                        {formules &&
                            _.map(formules, (f) => (
                                <th
                                    className={`${f.CodeFormule === formuleChoisie?.CodeFormule && 'select'} p-5`}
                                    key={f.CodeFormule}
                                >
                                    <div className={'position-relative'}>
                                        { ((f.CodeFormule === formulePreconisee) && (f.CodeFormule === formuleChoisie?.CodeFormule)) && <div
                                                className={
                                                    'rounded-bottom position-absolute text-white bg-dark py-1 px-2 f-12'
                                                }
                                                style={{ left: '50%', top: -50, transform: 'translateX(-50%)' }}
                                            >
                                                Actuelle & recommandée
                                            </div>
                                        }
                                        {(f.CodeFormule === formulePreconisee && f.CodeFormule !== formuleChoisie?.CodeFormule) && (
                                            <div
                                                className={
                                                    'rounded-bottom position-absolute text-white bg-dark py-1 px-2 f-12'
                                                }
                                                style={{ left: '50%', top: -50, transform: 'translateX(-50%)' }}
                                            >
                                                Recommandée
                                            </div>
                                        )}
                                        {(f.CodeFormule === formuleChoisie?.CodeFormule && f.CodeFormule !== formulePreconisee) && <div
                                            className={
                                                'rounded-bottom position-absolute text-white bg-primary py-1 px-2 f-12'
                                            }
                                            style={{ left: '50%', top: -50, transform: 'translateX(-50%)' }}
                                        >
                                            Formule actuelle
                                        </div>}
                                        <Field
                                            component={RadioBtn}
                                            type={'radio'}
                                            name={'DemandeTarif[Police][FormuleChoisie]'}
                                            value={f.CodeFormule}
                                            label={buildLabelFormule(f)}
                                            className={'cursor-pointer p-z3 w-100 h-100'}
                                            onChange={() => {
                                                changeValue(`options`, {});
                                                changeValue(`optionsFranchise`, {RAFRAVI: 'false', RAFRADTA: 'false',});
                                                changeValue(`optionsListe`, {});
                                            }}
                                        >
                                            {f.CodeFormule === formuleChoisie?.CodeFormule ? (
                                                <p
                                                    className={`btn btn-outline-primary btn-smaller cursor-pointer f-12`}
                                                >
                                                    <span className={'check-icon w-20'}></span> Ma formule
                                                </p>
                                            ) : (
                                                <p className={`btn btn-primary btn-smaller cursor-pointer f-12`}>
                                                    Choisir cette formule
                                                </p>
                                            )}
                                        </Field>
                                    </div>
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody className={'text-center'}>
                    {formules &&
                        !_.isEmpty(formules) &&
                        _.map(
                            _.filter(_.last(formules).ListeGaranties, (o) => o.Disponible === 'Inclusion'),
                            (garantie, i) => (
                                <tr key={i}>
                                    <th className={'p-4'}>
                                        <div className="col text-start">
                                            <p
                                                className={'f-14 text-dark fw-bold d-inline me-4'}
                                                onClick={() =>
                                                    showModal({
                                                        title: garantie.LibelleGarantie,
                                                        icon: 'info',
                                                        html: TextOptions[data?.typeVehicule][garantie.CodeGarantie]
                                                            .texteDeroulant,
                                                    })
                                                }
                                            >
                                                <span className="d-inline-block btn-help me-2">?</span>
                                                {garantie.LibelleGarantie}
                                            </p>
                                        </div>
                                    </th>
                                    {formules &&
                                        _.map(formules, (f, j) => (
                                            <td
                                                className={`${
                                                    f.CodeFormule === formuleChoisie?.CodeFormule && 'select'
                                                } align-middle`}
                                                key={j}
                                            >
                                                <div className="ps-0">
                                                    {_.find(
                                                        f.ListeGaranties,
                                                        (h) =>
                                                            h.CodeGarantie === garantie.CodeGarantie &&
                                                            h.Disponible === 'Inclusion',
                                                    ) ? (
                                                        <span className={'check-icon w-30'}></span>
                                                    ) : (
                                                        <img src={NonInclus} alt="Inclus" className={'align-middle'} />
                                                    )}
                                                </div>
                                            </td>
                                        ))}
                                </tr>
                            ),
                        )}
                    <tr>
                        <th></th>
                        {formules &&
                            _.map(formules, (f, j) => (
                                <td
                                    className={`${
                                        f.CodeFormule === formuleChoisie?.CodeFormule && 'select'
                                    } align-middle`}
                                    key={j}
                                >
                                    <Field
                                        component={RadioBtn}
                                        type={'radio'}
                                        name={'DemandeTarif[Police][FormuleChoisie]'}
                                        value={f.CodeFormule}
                                        label={buildLabelFormule(f)}
                                        className={'cursor-pointer p-3 w-100 h-100'}
                                    >
                                        {f.CodeFormule === formuleChoisie?.CodeFormule ? (
                                            <p className={`btn btn-outline-primary btn-smaller cursor-pointer f-12`}>
                                                <span className={'check-icon w-20'}></span> Ma formule
                                            </p>
                                        ) : (
                                            <p className={`btn btn-primary btn-smaller cursor-pointer f-12`}>
                                                Choisir cette formule
                                            </p>
                                        )}
                                    </Field>
                                </td>
                            ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TableFormule;
