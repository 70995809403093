import warning from '@amo/core/assets/images/icons/warning.svg';
import { Question } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

export const TauxAlcool = (props) => {
    const { nextQuestion, data } = props;

    return (
        <Question icon={warning} title={"Indiquez le <b>taux en mg/L</b><br/> d'air expulsé"}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-sm-6 col-12 my-2'}>
                    <Field
                        name={`DemandeTarif[Antecedents][TxAlcool]`}
                        component={Text}
                        type={'number'}
                        placeholder={'Exemple : 0,5'}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[Antecedents][TxAlcool]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
