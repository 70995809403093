import Cancel from '@amo/core/assets/images/AMO_Icons/Cancel.svg';
import { Question } from '@amo/core/components/containers';
import { DateField } from '@amo/core/components/forms';
import { maxDateToday, minDate36Mois } from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';

export const InformationsAnnulationPermis = (props) => {
    const { nextQuestion, data } = props;

    const isNotValid = () => {
        const date = _.get(data, 'DemandeTarif[Antecedents][DateAnnulationPermis]');

        return maxDateToday(date) || !moment(date, 'DD/MM/YYYY', true).isValid();
    };

    return (
        <Question icon={Cancel} title={"Indiquez la <b>date d'annulation</b><br/> de votre permis"}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-sm-6 col-12 my-2'}>
                    <Field
                        name={`DemandeTarif[Antecedents][DateAnnulationPermis]`}
                        label={'Permis annulé le'}
                        component={DateField}
                        placeholder={'Exemple : 22/03/2020'}
                        validate={[maxDateToday, minDate36Mois]}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${isNotValid() && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
