/* https://sweetalert2.github.io/ */
import Swal from 'sweetalert2';
import { SET_NOTIFICATION } from '../../../actions/core/notifications/notifications.actions';

/*
setNotification({
    entity: POST_CUSTOMER_UPDATE_PASSWORD,
    html: '<div><p>Votre demande a bien été prise en compte.</p></div>',
    icon: 'success',
    title: 'Félicitations !',
    confirmButtonText: 'J\'ai compris',
    successFunction: payload.meta.otherData.successFunction
})
*/

export const notificationsMiddleware = () => (next) => (action) => {
    next(action);

    if (action.type.includes(SET_NOTIFICATION)) {
        Swal.fire(action.meta).then((result) => {
            if (result.isConfirmed && action.meta.successFunction) {
                action.meta.successFunction();
            }
        });
    }
};
