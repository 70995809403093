import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { formValueSelector } from 'redux-form';
import {
    FETCH_CYLINDREES_VEHICULIER,
    fetchCylindreeVehicule,
} from '../../../../../../redux/actions/app/vehiculier/vehiculier.actions.js';
import { getCylindreesVehiculeUi, getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors.js';
import CylindreeVehicule from './CylindreeVehicule.jsx';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis-avenant');

    return {
        type: selector(state, `typeVehicule`),
        marque: selector(state, `marqueVehicule`),
        marque_id: selector(state, `marqueIdVehicule`),
        cylindree: selector(state, `cylindreeVehicule`),
        cylindrees: getCylindreesVehiculeUi(state),
        loading: getLoadingEntity(state, FETCH_CYLINDREES_VEHICULIER),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCylindreeVehicule: ({ type, marque_id }) => fetchCylindreeVehicule({ type, marque_id }),
        },
        dispatch,
    );

const CylindreeVehiculeContainer = compose(connect(mapStateToProps, mapDispatchToProps))(CylindreeVehicule);

export default CylindreeVehiculeContainer;
