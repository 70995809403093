import warning from '@amo/core/assets/images/icons/warning.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

export const ResiliationAncienAssureur = (props) => {
    const { nextQuestion, data, changeValue } = props;

    return (
        <Question
            icon={warning}
            title={
                "Avez-vous fait l'objet <b>d'une résiliation de contrat</b> par un assureur pour un de <b>vos véhicules</b>&nbsp;?"
            }
            subtitle={`Au cours des 3 dernières années...`}
            position={'bottom'}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Antecedents][ResiliationAssureur]'}
                        value={'1'}
                        label={'Oui'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Antecedents][ResiliationAssureur]'}
                        value={'0'}
                        label={'Non'}
                        onClick={() => {
                            changeValue('DemandeTarif[Antecedents][MotifResiliationAssureur]', '');
                            changeValue('DemandeTarif[Antecedents][DateResiliationAssureur]', '');
                            nextQuestion(300);
                        }}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[Antecedents][ResiliationAssureur]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
