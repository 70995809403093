import { checkContratStatutMiddleware } from './checkContratStatut/checkContratStatut.middleware';
import { fillMiddleware } from './fill/fill.middleware';
import { initMiddleware } from './init/init.middleware';
import { payMiddleware } from './pay/pay.middleware';
import { saveDevisMiddleware } from './saveDevis/saveDevis.middleware';
import { tarifMiddleware } from './tarif/tarif.middleware';
import { vehiculeMiddleware } from './vehicule/vehicule.middleware';
import { vehiculierMiddleware } from './vehiculier/vehiculier.middleware';

export const appMiddleware = [
    initMiddleware,
    fillMiddleware,
    vehiculierMiddleware,
    vehiculeMiddleware,
    tarifMiddleware,
    saveDevisMiddleware,
    payMiddleware,
    checkContratStatutMiddleware,
];
