import tchat from '@amo/core/assets/images/AMO_Icons/Service-client.svg';
import { Question } from '@amo/core/components/containers';
import _ from "lodash";
import {getStepBySlug} from "../../../../../../utils/function.js";

const Iban = (props) => {
    const { iban, titulaire, nextQuestion } = props;

    const formatIban = (iban) => {
        if (!iban) return "";
        const start = iban.substring(0, 4);
        const end = iban.substring(iban.length - 4);
        return `${start}***${end}`;
    }

    const onClickNext = () => {
        nextQuestion(300)
    };

    return (
        <Question
            icon={tchat}
            title={"Voici <b>l'IBAN</b> que nous avons d'enregistré pour le paiement de vos cotisations"}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <p>IBAN : {formatIban(iban)}</p>
                    <p>Titulaire du compte : {titulaire}</p>
                    <small className={'text-justify d-block mt-1'}>
                        Si jamais cet IBAN n'est plus correct, merci de faire la modification directement depuis votre
                        espace.
                    </small>
                </div>
            </div>

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => onClickNext()}>
                Continuer
            </button>
        </Question>
    );
};

export default Iban;
