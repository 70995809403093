import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { change, formValueSelector } from 'redux-form';
import { setVehicule } from '../../../../../../redux/actions/app/vehicule/vehicule.actions.js';
import {
    FETCH_MODELES_VEHICULIER,
    fetchModelesVehicule,
} from '../../../../../../redux/actions/app/vehiculier/vehiculier.actions.js';
import { getLoadingEntity, getModelesVehiculeUi } from '../../../../../../redux/selectors/ui/ui.selectors.js';
import { getSouscriptionDisabledVehicule } from '../../../../../../redux/selectors/vehicule/vehicule.selectors.js';
import ModeleVehicule from './ModeleVehicule.jsx';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis-avenant');

    return {
        type: selector(state, `typeVehicule`),
        marque_id: selector(state, `marqueIdVehicule`),
        cylindree: selector(state, `cylindreeVehicule`),
        modele: selector(state, `modeleVehicule`),
        modeles: getModelesVehiculeUi(state),
        loading: getLoadingEntity(state, FETCH_MODELES_VEHICULIER),
        vehiculeIsDisabled: getSouscriptionDisabledVehicule(state),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchModelesVehicule: ({ type, marque_id, cylindree }) => fetchModelesVehicule({ type, marque_id, cylindree }),
            changeValue: (field, value) => change('devis-avenant', field, value),
            setVehicule: (vehicule) => setVehicule({ data: { data: vehicule } }),
        },
        dispatch,
    );

const ModeleVehiculeContainer = compose(connect(mapStateToProps, mapDispatchToProps))(ModeleVehicule);

export default ModeleVehiculeContainer;
