import Calendar from '@amo/core/assets/images/AMO_Icons/Calendar.svg';
import { Question } from '@amo/core/components/containers';
import { DateField } from '@amo/core/components/forms';
import { maxDateToday, min14Year, min16Year, min18Year } from '@amo/core/utils/validateField.js';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';

export const DateNaissanceRepresentantLegal = (props) => {
    const { nextQuestion, data } = props;

    const isNotValid = () => {
        let date = _.get(data, 'DemandeTarif[ListePersonnes][1][DateNaissance]');

        return maxDateToday(date) || min18Year(date) || !moment(date, 'DD/MM/YYYY', true).isValid();
    };

    return (
        <Question
            icon={Calendar}
            title={'Quelle est la <b>date de naissance</b><br/> du <b>représentant légal</b>&nbsp;?'}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        name={'DemandeTarif[ListePersonnes][1][DateNaissance]'}
                        component={DateField}
                        placeholder={'Exemple : 22/03/1990'}
                        validate={[
                            maxDateToday,
                            Number(data?.cylindreeVehicule) <= 50
                                ? min14Year
                                : Number(data?.cylindreeVehicule) <= 125
                                ? min16Year
                                : min18Year,
                        ]}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${isNotValid() && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
