import casques from '@amo/core/assets/images/icons/casques.svg';
import { Question } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import { normalizeNumber } from '@amo/core/utils/normalize.js';
import { maxValeurVehicule } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { Field } from 'redux-form';

export const ValeurVehicule = (props) => {
    const { nextQuestion, data, title } = props;

    return (
        <Question icon={casques} title={title}>
            <div className={'row justify-content-center'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Text}
                        type={'tel'}
                        typeFormat={'euro'}
                        name={'DemandeTarif[Vehicule][ValeurVehicule]'}
                        placeholder={'Exemple : 1200'}
                        normalize={normalizeNumber}
                        validate={maxValeurVehicule}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[Vehicule][ValeurVehicule]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
