import tchat from '@amo/core/assets/images/AMO_Icons/Service-client.svg';
import { Question } from '@amo/core/components/containers';
import {Field} from "redux-form";
import {Radio} from "@amo/core/components/forms";
import _ from "lodash";

const AdresseActuelle = (props) => {
    const { adresse1, adresse2, cp, ville, nextQuestion, data, changeValue } = props;


    return (
        <Question
            icon={tchat}
            title={"Est-ce que cette <b>adresse postale</b> est <b>toujours actuelle</b>&nbsp;?"}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'}>
                    {adresse1 ? <p>Adresse: {adresse1} </p> : <p>Non renseigné</p>}
                    {cp && ville ? <p>Ville : {cp} - {ville}</p> : <p>Non renseigné</p>}
                </div>
            </div>

            <div className={'row justify-content-center'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'changeAdresse'}
                        value={'1'}
                        label={'Oui'}
                        onClick={() => {
                            changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', adresse1);
                            changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', adresse2);
                            changeValue('DemandeTarif[ListePersonnes][0][Ville]', ville);
                            changeValue('DemandeTarif[ListePersonnes][0][CP]', cp);
                            nextQuestion(300)
                        }}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'changeAdresse'}
                        value={'0'}
                        label={'Non'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${!_.get(data, 'changeAdresse') && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};

export default AdresseActuelle;
