import warning from '@amo/core/assets/images/icons/warning.svg';
import { Question } from '@amo/core/components/containers';
import { DateField, Select } from '@amo/core/components/forms';
import { maxDateToday, minDate36Mois } from '@amo/core/utils/validateField.js';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';

export const InformationsResiliationAncienAssureur = (props) => {
    const { nextQuestion, data } = props;

    const isNotValid = () => {
        const date = _.get(data, 'DemandeTarif[Antecedents][DateResiliationAssureur]');

        return (
            maxDateToday(date) ||
            !moment(date, 'DD/MM/YYYY', true).isValid() ||
            !_.get(data, 'DemandeTarif[Antecedents][MotifResiliationAssureur]')
        );
    };

    return (
        <Question icon={warning} title={'Indiquez <b>la date</b> et le <b>motif de résiliation</b>'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-sm-6 col-12 my-2'}>
                    <Field
                        name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}
                        component={Select}
                        label={'Sélectionnez le motif de résiliation'}
                    >
                        <option value={''}>Sélectionnez le motif de résiliation</option>
                        <option value={'NP'}>Non paiement de prime</option>
                        <option value={'FS'}>Fréquence sinistres</option>
                        <option value={'FD'}>Fausse déclaration</option>
                        <option value={'A'}>Autres</option>
                    </Field>
                </div>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-6 col-sm-6 col-12 my-2'}>
                        <Field
                            name={`DemandeTarif[Antecedents][DateResiliationAssureur]`}
                            label={'A quelle date ?'}
                            component={DateField}
                            placeholder={'Exemple : 22/03/2020'}
                            validate={[maxDateToday, minDate36Mois]}
                        />
                    </div>
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${isNotValid() && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
