import {UsageVehicule, UsageNonLivraison} from "@amo/core/components/questions";
import ChangementAdresse from "../ChangementAdresse/ChangementAdresse.jsx";
import AdresseActuelleContainer from "../AdresseActuelle/AdresseActuelleContainer.js";
import _ from "lodash";
import ContratNomSociete from "./ContratNomSociete/ContratNomSociete.jsx";
import TypeVehicule from "../TypeVehicule/TypeVehicule.jsx";
import MarqueVehiculeContainer from "../MarqueVehicule/MarqueVehiculeContainer.js";
import CylindreeVehiculeContainer from "../CylindreeVehicule/CylindreeVehiculeContainer.js";
import ModeleVehiculeContainer from "../ModeleVehicule/ModeleVehiculeContainer.js";

const ChangementUsage = (props) => {
    const { data, id, source } = props;

    return (
        <div id={id}>
            <UsageVehicule {...props} title={'A quelle(s) <b>occasion(s)</b> <br/>utilisez-vous <b>votre véhicule</b>&nbsp;?'}/>

            {((source === 'courtier' || source === 'site') && _.get(data, 'DemandeTarif[Vehicule][UsageVehicule]') === '3') && <ContratNomSociete {...props} />}

            {data?.cylindreeVehicule === '50' && <UsageNonLivraison {...props} />}

            <AdresseActuelleContainer {...props} />

            {data?.changeAdresse === '0' && <ChangementAdresse {...props} />}
        </div>
    );
};

export default ChangementUsage;
