import Trophee from '@amo/core/assets/images/AMO_Icons/Trophee.svg';
import { Question } from '@amo/core/components/containers';

export const GoToBesoin = (props) => {
    const { nextQuestion } = props;

    return (
        <Question icon={Trophee} title={'<b>Tenez bon</b>, nous avons <br/>presque terminé&nbsp;! '}>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-3 f-18'}>
                        Dans quelques instants, nous allons pouvoir établir{' '}
                        <span className={'fw-bold text-secondary'}>votre devis sur mesure</span>&nbsp;😃
                    </p>
                    <p className={'mt-3 f-18'}>
                        Avant ça, nous avons encore <b>quelques questions</b> à vous poser, pour{' '}
                        <span className={'fw-bold'}>identifier vos besoins.</span>
                    </p>
                </div>
            </div>
            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                Allons-y !
            </button>
        </Question>
    );
};
