import info from '@amo/core/assets/images/icons/info.svg';
import { BulleAide } from '@amo/core/components/containers';
import { Checkbox } from '@amo/core/components/forms';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';

export const ImmatOuMarquage = (props) => {
    const { data, hasBulle } = props;
    const date = _.get(data, 'DemandeTarif[Vehicule][DateMEC]');
    const isNotImmat = _.get(data, 'DemandeTarif[Vehicule][VehiculeImmatricule]') === "0";
    const dateMEC = moment(date, 'DD/MM/YYYY') > moment().startOf('day').subtract(30, 'days');

    return (
        <>
            {(dateMEC || (_.includes(['quad', 'verte'], data?.typeVehicule) && isNotImmat)) && (
                <div className={'row justify-content-center'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-lg-8 col-md-6 col-sm-6 col-12 mb-4 text-start'}>
                            <Field
                                name="immatriculation_check"
                                component={Checkbox}
                                format={(v) => v === '0'}
                                normalize={(v) => (v ? '0' : '1')}
                            >
                                <p className={'mt-1'}>Le véhicule n'a pas encore d'immatriculation</p>
                            </Field>
                        </div>
                    </div>
                </div>
            )}
            {hasBulle && <BulleAide
                icon={info}
                title={'Le numéro d’immatriculation figure sur la carte grise (A).'}
                text={
                    'Si votre véhicule est neuf, vous pouvez indiquer le numéro d’immatriculation provisoire. <strong>N’oubliez pas de nous communiquer le numéro définitif dès que vous l’aurez obtenu.</strong>'
                }
                className={'mt-0 col-lg-8 offset-lg-2 col-md-12'}
            /> }
        </>
    );
};