import { APP_NAME } from '@amo/core/constants/constants.js';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import moment from 'moment';
import { setMaintenance } from '../../../actions/app/ui/ui.actions';
import { API_REQUEST, apiError, apiSuccess } from '../../../actions/core/api/api.actions';
import { clearLocalStorage } from '../../../actions/core/localStorage/localStorage.actions';

export const apiMiddleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        if (action.type.includes(API_REQUEST)) {
            const {
                meta: { url, method, entity, otherData },
                data,
            } = action.payload;

            axios({ method, url, data })
                .then((response) => {
                    dispatch(apiSuccess({ response: response.data, entity, otherData }));

                    /* Refresh timer token */
                    const storage = JSON.parse(localStorage.getItem(APP_NAME));
                    const expire = moment().toJSON();

                    localStorage.setItem(APP_NAME, JSON.stringify({ ...storage, expire }));
                })
                .catch((error) => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 401:
                                dispatch(apiError({ error, entity, otherData }));
                                break;

                            case 503:
                                dispatch(apiError({ error, entity, otherData }));
                                dispatch(setMaintenance({ state: true, entity }));
                                break;

                            case 498:
                                dispatch(apiError({ error, entity, otherData }));
                                dispatch(clearLocalStorage({ entity: 'API' }));
                                window.location = window.location.pathname;
                                break;

                            default:
                                dispatch(apiError({ error, entity, otherData }));
                        }
                    } else {
                        dispatch(apiError({ error, entity, otherData })); // OPTION REQUEST
                    }
                });
        }
    };
