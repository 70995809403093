import EmailClient from './EmailClient/EmailClient';
import IbanContainer from './Iban/IbanContainer.js';
import NumeroTelContainer from './NumeroTel/NumeroTelContainer';

const Coordonnees = (props) => {
    const { id, iban } = props;

    return (
        <div id={id}>
            <EmailClient {...props} />
            <NumeroTelContainer {...props} />
            {iban && <IbanContainer {...props} /> }

        </div>
    );
};

export default Coordonnees;
