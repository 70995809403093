import _ from 'lodash';

export const isFavoriteBrand = ({ marque, type }) => {
    const favoritesMarques = {
        moto: ['BMW', 'DUCATI', 'HARLEY-DAVIDSON', 'HONDA', 'KAWASAKI', 'KTM', 'SUZUKI', 'YAMAHA', 'TRIUMPH'],
        scooter: ['BMW', 'DERBI', 'HONDA', 'KYMCO', 'MBK', 'PEUGEOT', 'PIAGGIO', 'YAMAHA', 'SYM', 'UNU'],
        50: ['DERBI', 'KYMCO', 'MBK', 'PEUGEOT', 'PIAGGIO', 'YAMAHA'],
        quad: ['ARCTIC CAT', 'CAN-AM', 'CF MOTO', 'GOES', 'KYMCO', 'POLARIS', 'TGB', 'YAMAHA'],
        verte: ['HONDA', 'HUSABERG', 'HUSQVARNA', 'KAWASAKI', 'KTM', 'SHERCO', 'YAMAHA'],
    };

    return _.includes(favoritesMarques[type], marque);
};
