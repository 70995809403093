import Incendie from '@amo/core/assets/images/icons/Incendie.svg';
import InformationsBleu from '@amo/core/assets/images/icons/informations-bleu.png';
import InformationsGris from '@amo/core/assets/images/icons/informations-gris.png';
import Warning from '@amo/core/assets/images/icons/warning.svg';
import { RadioInline } from '@amo/core/components/forms';
import { getNumberInString, showModal } from '@amo/core/utils/functions';
import { normalizeEuro } from '@amo/core/utils/normalize';
import _ from 'lodash';
import { useEffect } from 'react';
import { Field } from 'redux-form';
import './franchises.scss';

const Franchises = (props) => {
    const { franchises, fractionnementChoisi, changeValue, data, codePromo, formules, codeFormuleChoisie } = props;

    useEffect(() => {
        /* Si la franchise se trouve dans la formule et qu'elle est coché on la garde, sinon on la reset */
        const arrayFranchises = ['RAFRAVI', 'RAFRADTA'];
        arrayFranchises.forEach((franchise) => {
            if (_.find(franchises.data, ['value', franchise])) {
                if (_.get(data, `optionsFranchise[${franchise}]`)) {
                    changeValue(`optionsFranchise[${franchise}]`, _.get(data, `optionsFranchise[${franchise}]`));
                } else {
                    changeValue(`optionsFranchise[${franchise}]`, 'false');
                }
            } else {
                changeValue(`optionsFranchise[${franchise}]`, null);
            }
        });
    }, [_.get(data, 'DemandeTarif[Police][FormuleChoisie]')]);

    const img = {
        RAFRAVI: Incendie,
        RAFRADTA: Warning,
    };

    const getStringFranchise = (code) => {
        const codeGarantie = code === 'RAFRADTA' ? 'DTA' : 'VI';
        const formule = _.find(formules, ['CodeFormule', codeFormuleChoisie]);
        const franchise = _.find(formule.ListeGaranties, ['CodeGarantie', codeGarantie]);

        return franchise.ConditionsIndemnisationSansRachat;
    };

    return (
        <div id={'franchises'}>
            <div className={`row mt-4`}>
                <div className={'col-12'}>
                    <p className={'f-20 text-dark fw-bold text-center title-franchise'}>Détail de vos franchises</p>
                    <p
                        className={'f-12 text-secondary my-3'}
                        onClick={() =>
                            showModal({ title: 'Rachat de franchise', icon: 'info', html: franchises.texteDeroulant })
                        }
                    >
                        <img src={InformationsBleu} alt="?" className={'align-middle'} />{' '}
                        <span className={'pb-1 cursor-pointer underline-link'}>
                            À quoi ça sert de racheter mes franchises ?
                        </span>
                    </p>
                </div>

                {franchises.data?.map((franchise, index) => (
                    <div className={'col-12'} key={index}>
                        <div className={'bg-white border rounded p-4 mb-3 row bloc-franchise'}>
                            <div className="col-12 col-lg-6">
                                <div className="row">
                                    <div className="col-lg-2 col-3">
                                        <img src={img[franchise.value]} alt="Icon" width={48} />
                                    </div>
                                    <div className="col-lg-10 col-9">
                                        <div className="row text-start">
                                            <div className="col-12 text-dark f-16 fw-bold">{franchise.label}</div>
                                            <div className="col-12">
                                                <p
                                                    className={'f-12 text-grey'}
                                                    onClick={() =>
                                                        showModal({
                                                            title: franchise.label,
                                                            icon: 'info',
                                                            html: franchise.dataAffichage.text,
                                                        })
                                                    }
                                                >
                                                    <img src={InformationsGris} alt="?" className={'align-middle'} />{' '}
                                                    <u className={'pb-1 cursor-pointer'}>En savoir plus</u>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-2 ps-4 ps-lg-0 mt-lg-0 mt-4 franchise-input">
                                <Field
                                    name={`optionsFranchise[${franchise.value}]`}
                                    component={RadioInline}
                                    type={'radio'}
                                    value={'false'}
                                >
                                    <p className={'text-start text-grey f-14 ms-3'} style={{ paddingTop: 5 }}>
                                        <span className={'f-16 fw-bold text-dark'}>
                                            {getNumberInString(getStringFranchise(franchise.value))}€
                                        </span>{' '}
                                        de franchise
                                    </p>
                                </Field>
                            </div>

                            <div className="col-12 col-lg-4 ps-4 ps-lg-0 mt-lg-0 mt-4">
                                <Field
                                    name={`optionsFranchise[${franchise.value}]`}
                                    component={RadioInline}
                                    type={'radio'}
                                    value={'true'}
                                >
                                    <p className={'text-start text-grey f-14 ms-3'}>
                                        <span className={'f-16 fw-bold text-dark'}>0€</span> de franchise
                                        {codePromo?.Eligible &&
                                        (codePromo?.TypePromo === 'ReductionPourcent' ||
                                            franchise.dataAffichage.prix > franchise.dataAffichage.prix_promo) ? (
                                            <small className={'text-dark f-14 mb-2 d-block'}>
                                                Je rachète cette franchise{' '}
                                                {normalizeEuro(franchise.dataAffichage.prix_promo)} TTC /{' '}
                                                {fractionnementChoisi === 'M' ? 'mois' : 'an'} <br />
                                                <i className={'text-grey text-decoration-line-through'}>
                                                    (au lieu de {normalizeEuro(franchise.dataAffichage.prix)})
                                                </i>
                                            </small>
                                        ) : (
                                            <small className={'text-dark f-14 mb-2 d-block'}>
                                                Je rachète cette franchise{' '}
                                                {normalizeEuro(franchise.dataAffichage.prix_promo)} TTC /{' '}
                                                {fractionnementChoisi === 'M' ? 'mois' : 'an'}
                                            </small>
                                        )}
                                    </p>
                                </Field>
                            </div>
                        </div>
                    </div>
                ))}

                <div className={'col-12'}>
                    <p className={'f-14 text-grey text-center mb-3'}>
                        Les franchises, seuils d’intervention et limites de garanties sont précisés aux dispositions
                        générales.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Franchises;
