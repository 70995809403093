import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import AprilClient from '@amo/core/assets/images/AMO_Icons/AprilClient.svg';

const ContratNomSociete = (props) => {
    const { nextQuestion, data} = props;

    return (
        <Question
            title={"Est-ce que vous souhaitez passer le contrat au nom d'une société&nbsp;?"}
            icon={AprilClient}
            position={'bottom'}
        >
            <div className={'row justify-content-center'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'contratNomSociete'}
                        value={'1'}
                        label={'Oui'}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'contratNomSociete'}
                        value={'0'}
                        label={'Non'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
            </div>

            {_.get(data, 'contratNomSociete') === '1' && (
                <div className="alert alert-danger mt-3 col-lg-8 offset-lg-2" role="alert">
                    <p className="mb-1">Passer le contrat au nom d’une société nécessite un changement de souscripteur et cela ne rentre pas dans nos conditions d’avenant.</p>
                    <p className="mb-0">Merci de nous appeler au 02.47.51.07.07 pour faire le point sur votre contrat.</p>
                </div>
            )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    (!_.get(data, 'contratNomSociete') || _.get(data, 'contratNomSociete') === '1') && 'disabled'
                }`}
                onClick={() => nextQuestion()}>
                Continuer
            </button>
        </Question>
    );
};

export default ContratNomSociete
