import chevron from '@amo/core/assets/images/icons/chevron-left-white.svg';
import { getStepBySlug } from '../../../../utils/function';
import NavBar from './NavBar';
import _ from "lodash";

const Header = (props) => {
    const { previousQuestion, indexQuestion, step, nbQuestions, routes, logo, nameWhitelabel, source, data, goTo } = props;
    const motifAvenant = _.get(data, 'DemandeTarif[Police][MotifAvenant]')

    return (
        <div className={'header f-12'}>
            <div className={'py-lg-4 px-lg-5 px-2 py-2'}>
                <div className={'container-fluid'}>
                    <div className={`row justify-content-start align-items-center`}>
                        {(indexQuestion === 0 && step === 0 && step <= 7) ||
                        step === getStepBySlug('confirmation-devis') ? null : (
                            <div className="col-3 d-lg-none d-xl-none">
                                <span className={'btn btn-return-small me-3'} onClick={() => previousQuestion()}>
                                    <img src={chevron} alt="<" width={12} />
                                </span>
                            </div>
                        )}

                        {step === getStepBySlug('confirmation-devis') && <div className={'col-xl-2 d-none d-lg-block text-start'}>
                            <span
                                className={`btn btn-return btn-smaller  me-3`}
                                onClick={() => goTo({ step: getStepBySlug('votre-tarif'), question: 0 })}
                            >Question précédente</span>
                        </div>
                        }

                        {(step !== getStepBySlug('confirmation-devis') && (motifAvenant !== '10' && motifAvenant !== '8' && motifAvenant !== '11') ) ? (
                            <div className={'col-xl-2 d-none d-lg-block text-start'}>
                                {(indexQuestion === 0 && step === 0 && step <= 7) ? null : (
                                    <span
                                        className={`btn btn-return btn-smaller  me-3`}
                                        onClick={() => previousQuestion()}
                                    >
                                        Question précédente
                                    </span>
                                )}
                            </div>
                        ) : <div className={'col-xl-2 d-none d-lg-block text-start'}>&nbsp;</div>}

                        <div className="col-4 col-md-2 col-xl-1 text-center">
                            <img src={logo} alt={nameWhitelabel} width={115} />
                        </div>

                        {step !== getStepBySlug('confirmation-devis') && (
                            <div className={`d-none d-lg-block col-xl-6 text-center`}>
                                <NavBar
                                    nbQuestions={nbQuestions}
                                    routes={routes}
                                    activeStep={step}
                                    indexQuestion={indexQuestion}
                                    source={source}
                                />
                            </div>
                        )}

                        {step !== getStepBySlug('confirmation-devis') && (
                            <div className="d-block d-lg-none d-xl-none col-12 text-center mt-4">
                                <NavBar
                                    nbQuestions={nbQuestions}
                                    routes={routes}
                                    activeStep={step}
                                    indexQuestion={indexQuestion}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
