import { connect } from 'react-redux';
import { getEmailSouscripteurTarif, getUrlPDFDevisInit } from '../../../../../redux/selectors/init/init.selectors';
import { getMarqueVehicule, getModeleVehicule } from '../../../../../redux/selectors/vehicule/vehicule.selectors';
import ConfirmationDevis from './ConfirmationDevis';

const mapStateToProps = (state) => {
    return {
        email: getEmailSouscripteurTarif(state),
        pdfDevis: getUrlPDFDevisInit(state),
        modele: getModeleVehicule(state),
        marque: getMarqueVehicule(state),
    };
};

const ConfirmationDevisContainer = connect(mapStateToProps)(ConfirmationDevis);

export default ConfirmationDevisContainer;
