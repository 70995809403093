import Parking from '@amo/core/assets/images/AMO_Icons/Parking.svg';
import { Question } from '@amo/core/components/containers';
import { PostalCodeCity } from '@amo/core/components/forms';
import { excludeCodePostal } from '@amo/core/utils/validateField';
import _ from 'lodash';

const LieuStationnement = (props) => {
    const { nextQuestion, data, changeValue, token, canal } = props;

    return (
        <Question icon={Parking} title={'Où <b>stationnez</b>-vous votre <b>véhicule</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <PostalCodeCity
                        token={token}
                        canal={canal}
                        id={'lieuStationnement'}
                        nameVille={'DemandeTarif[Vehicule][VilleGarage]'}
                        nameCodePostal={'DemandeTarif[Vehicule][CodePostalGarage]'}
                        validate={excludeCodePostal}
                        placeholder={'Exemple : 75011 ou PARIS'}
                        changeValue={changeValue}
                        defaultValue={
                            _.get(data, 'DemandeTarif[Vehicule][CodePostalGarage]') &&
                            `${_.get(data, 'DemandeTarif[Vehicule][CodePostalGarage]')} ${_.startCase(
                                _.toLower(_.get(data, 'DemandeTarif[Vehicule][VilleGarage]')),
                            )}`
                        }
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    (!_.get(data, 'DemandeTarif[Vehicule][CodePostalGarage]') ||
                        !_.get(data, 'DemandeTarif[Vehicule][VilleGarage]')) &&
                    'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};

export default LieuStationnement;
