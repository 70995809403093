import _ from 'lodash';
import options from '@amo/core/utils/json/options.json';

export const reformatFormules = (formules, typeVehicule) => {
    if (formules.length > 0) {
        return _.map(formules, (f) => {
            return {
                ...f,
                ListeGaranties: _.map(_.orderBy(f.ListeGaranties, ['OrdreAffichage'], ['asc']), (g) => {
                    const textesGroupe = options[typeVehicule][g?.IdGroupeGarantie];
                    return {
                        ...g,
                        ...textesGroupe,
                    };
                }),
            };
        });
    }

    return [];
};
