import React from 'react';
import './franchises.scss';
import Incendie from "@amo/core/assets/images/icons/Incendie.svg";
import Warning from "@amo/core/assets/images/icons/warning.svg";

const FranchisesInfo = (props) => {
    const {VI, DTA} = props
    let franchisesListe = [];

    const img = {
        VI: Incendie,
        DTA: Warning,
    };

    if(VI){
        franchisesListe = [...franchisesListe, VI];
    }
    if(DTA){
        franchisesListe = [...franchisesListe, DTA];
    }

    return (
        <div id={'franchises'}>
            <div className={`row mt-4`}>
                <div className={'col-12'}>
                    <p className={'f-20 text-dark fw-bold text-center title-franchise mb-3'}>Détail de vos franchises</p>
                </div>

                {franchisesListe?.map((franchise, index) => (
                    <div className={'col-12'} key={index}>
                        <div className={'bg-white border rounded p-4 mb-3 row bloc-franchise'}>
                            <div className="col-12 col-lg-6">
                                <div className="row">
                                    <div className="col-lg-2 col-3">
                                        <img src={img[franchise.IdGroupeGarantie]} alt="Icon" width={48} />
                                    </div>
                                    <div className="col-lg-10 col-9">
                                        <div className="row text-start">
                                            <div className="col-12 text-dark f-16 fw-bold">{franchise.LibelleBouton}</div>
                                            <div className="col-12">
                                                <p className={'f-12 text-grey'}>
                                                    {franchise.ConditionsIndemnisation}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                <div className={'col-12'}>
                    <p className={'f-14 text-grey text-center mb-3'}>
                        Les franchises, seuils d’intervention et limites de garanties sont précisés aux dispositions
                        générales.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FranchisesInfo;