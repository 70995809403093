import { connect } from 'react-redux';
import { FILL } from '../../../../../../redux/actions/app/fill/fill.actions';
import { getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import NumeroTel from './NumeroTel';

const mapStateToProps = (state) => {
    return {
        loadingPostFill: getLoadingEntity(state, FILL),
    };
};

const NumeroTelContainer = connect(mapStateToProps)(NumeroTel);

export default NumeroTelContainer;
