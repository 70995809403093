import { Text } from '@amo/core/components/forms';
import { Field } from 'redux-form';

export const Prenom = (props) => {
    const { index, disabled } = props;

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    name={`DemandeTarif[ListePersonnes][${index}][Prenom]`}
                    label={`Prénom ${index === 1 ? 'du souscripteur' : ''}`}
                    component={Text}
                    type={'text'}
                    typeFormat={'text'}
                    required
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
