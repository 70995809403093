import Timer from '@amo/core/assets/images/AMO_Icons/Timer.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import { Field } from 'redux-form';
import _ from "lodash";

export const PossedeVehiculePlus3Mois = (props) => {
    const { nextQuestion, data, changeValue } = props;

    return (
        <Question icon={Timer} title={'Possédez-vous ce <b>véhicule</b><br/> depuis <b>plus de 3 mois</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][PossessionPlusDe3Mois]'}
                        value={'1'}
                        label={'Oui'}
                        onClick={() => {
                            changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '');
                            nextQuestion(300);
                        }}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][PossessionPlusDe3Mois]'}
                        value={'0'}
                        label={'Non'}
                        onClick={() => {
                            changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '1');
                            nextQuestion(300);
                        }}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${!_.get(data, 'DemandeTarif[Vehicule][PossessionPlusDe3Mois]') && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};