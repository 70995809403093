export const FILL = '[Fill]';

export const POST_FILL = `${FILL} Post`;

export const postFill = ({ step, form, query, queryTarif, getTarif, codePromo }) => ({
    type: POST_FILL,
    payload: {
        data: query,
        body: form,
        meta: {
            otherData: {
                step,
                form,
                queryTarif,
                getTarif,
                codePromo,
            },
        },
    },
});
