import { Autocomplete, Text } from '@amo/core/components/forms';
import { getCountries } from '@amo/core/utils/requestsApi';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';

export const PaysNaissance = (props) => {
    const { index, data, disabled, required } = props;
    const [countriesFormat, setCountriesFormat] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initBirth, setInitBirth] = useState(false);

    useEffect(() => {
        (async function fetchCountries() {
            let countriesFormat;
            let countries = await getCountries(setLoading);

            countriesFormat = countries.map((country) => ({
                value: country.id,
                label: country.name,
            }));

            setCountriesFormat(countriesFormat);
        })();
    }, []);

    useEffect(() => {
        setInitBirth(
            _.find(countriesFormat, ['value', _.get(data, 'DemandeTarif[ListePersonnes][0][BirthCountry]')])?.label,
        );
    });

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    component={disabled ? Text : Autocomplete}
                    typeFormat={'postal'}
                    name={`DemandeTarif[ListePersonnes][${index}][BirthCountry]`}
                    placeholder={initBirth || 'Exemple : France'}
                    data={countriesFormat}
                    label={`Pays de naissance ${index === 1 ? 'du souscripteur' : ''}`}
                    disabled={disabled}
                    required={required}
                />
                {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
            </div>
        </div>
    );
};