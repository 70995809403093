import TypeVehicule from "../TypeVehicule/TypeVehicule";
import MarqueVehiculeContainer from "../MarqueVehicule/MarqueVehiculeContainer.js";
import CylindreeVehiculeContainer from "../CylindreeVehicule/CylindreeVehiculeContainer.js";
import ModeleVehiculeContainer from "../ModeleVehicule/ModeleVehiculeContainer.js";
import DateMec from "./DateMEC/DateMEC";
import _ from "lodash";
import {
    VehiculeImmatricule,
    UsageNonLivraison,
    ValeurVehicule,
    Assure3DerniersMois,
    CarteGriseFrancaise,
    UsageVehicule,
    AssuranceAuto,
    InformationsAssuranceAuto,
    AssuranceMoto,
    InformationsAssuranceMoto,
    ValeurCrmMoto,
    ValeurCrmAuto, PossedeVehiculePlus3Mois, QuestionImmatriculation, TitulaireCarteGrise
} from "@amo/core/components/questions";
import ChoixPermis from "./ChoixPermis/ChoixPermis.jsx";
import moment from "moment";
import ContratNomSociete from "../ChangementUsage/ContratNomSociete/ContratNomSociete.jsx";
import {useEffect} from "react";
import InformationsVehicule from "../InformationsVehicule/InformationsVehicule.jsx";
import {getInformationVehicule} from "@amo/core/utils/index.js";
import DateAchat from "./DateAchat/DateAchat.jsx";

const ChangementVehicule = (props) => {
    const { data, id, source, initialValues, changeValue, vehicule, codeProduit } = props;

    useEffect(() => {
        changeValue('typeVehicule', null)
        changeValue('marqueVehicule', null)
        changeValue('cylindreeVehicule', null)
        changeValue('DemandeTarif[Vehicule][Immatriculation]', null)
    }, [])

    return (
        <div id={id}>
            <TypeVehicule {...props} />
            {data?.typeVehicule && <MarqueVehiculeContainer {...props} />}
            {data?.marqueVehicule && <CylindreeVehiculeContainer {...props} />}
            {data?.cylindreeVehicule && <ModeleVehiculeContainer {...props} />}
            {vehicule?.sra && getInformationVehicule(vehicule) !== '' && <InformationsVehicule {...props} /> }
            <DateMec {...props} />
            <DateAchat {...props} />

            <QuestionImmatriculation {...props} />

            <TitulaireCarteGrise {...props} />

            {/* champs caché depuis ajout dateAchat moment(_.get(data, 'DemandeTarif[Vehicule][DateMEC]'), 'DD/MM/YYYY', true) <=
                moment().subtract(3, 'months') && <PossedeVehiculePlus3Mois {...props} />
            */}

            {_.get(data, 'DemandeTarif[Vehicule][PossessionPlusDe3Mois]') === '1' && <Assure3DerniersMois {...props} />}

            {_.includes(['quad', 'verte'], data?.typeVehicule) && (
                <>
                    {/*<ValeurVehicule title={'Quelle est <b>la valeur</b> de votre <b>véhicule</b>&nbsp;?'} {...props} />*/}
                    <VehiculeImmatricule title={'Votre véhicule est-il <b>immatriculé</b>&nbsp;?'} {...props} />
                </>
            )}

            {_.get(data, 'DemandeTarif[Vehicule][VehiculeImmatricule]') === '1' && <CarteGriseFrancaise {...props} />}
            {!_.includes(['quad', 'verte'], data?.typeVehicule) &&
                <>
                    <UsageVehicule title={'A quelle(s) <b>occasion(s)</b> <br/>utilisez-vous <b>votre véhicule</b>&nbsp;?'} {...props} />
                    {((source === 'courtier' || source === 'site') && _.get(data, 'DemandeTarif[Vehicule][UsageVehicule]') === '3') && <ContratNomSociete {...props} />}
                </>
            }

            {data?.cylindreeVehicule === '50' && <UsageNonLivraison {...props} />}

            <ChoixPermis {...props} />
            { _.includes(['7311_PVE', '7311_PVK', '7311_SC'], codeProduit) &&
                !_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                    return _.includes(['AM', 'A1', 'A2', 'A', 'B'], permis?.TypePermis);
                }) && <ValeurCrmMoto {...props} />}

            { _.includes(['7311_PVE', '7311_PVK', '7311_SC'], codeProduit)  &&
                !_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                    return _.includes(['B'], permis?.TypePermis);
                }) && <ValeurCrmAuto {...props} />}

            { _.includes(['7311_PVE', '7311_PVK', '7311_SC'], codeProduit)  &&
                !_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                    return _.includes(['B'], permis?.TypePermis);
                }) && <AssuranceAuto {...props} antecedentsSur3ans={false} />}

            {_.get(data, 'assure_auto') === '1' && (
                <InformationsAssuranceAuto {...props} antecedentsSur3ans={false} />
            )}

            { _.includes(['7311_PVE', '7311_PVK', '7311_SC'], codeProduit)  &&
                !_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                    return _.includes(['A', 'A1', 'A2'], permis?.TypePermis);
                }) && <AssuranceMoto {...props} antecedentsSur3ans={false} />}

            {_.get(data, 'assure_moto') === '1' && (
                <InformationsAssuranceMoto {...props} antecedentsSur3ans={false} />
            )}
        </div>
    );
};

export default ChangementVehicule;
