import _ from 'lodash';
import {
    AnnulationPermis, AssuranceAuto, AssuranceMoto,
    CondamnationAlcoolemie,
    CondamnationConduiteSansAssurance,
    CondamnationDelitFuite,
    CondamnationStupefiants,
    GoToBesoin,
    InformationsAnnulationPermis, InformationsAssuranceAuto, InformationsAssuranceMoto,
    InformationsResiliationAncienAssureur,
    InformationsSuspensionPermis,
    MalusQuadVerte,
    ResiliationAncienAssureur, Sinistres,
    SinistresAutoMoto,
    SuspensionPermis,
    TauxAlcool, ValeurCrmAuto, ValeurCrmMoto, ValeurVehicule, VehiculeImmatricule
} from "@amo/core/components/questions"
import ClientTelephone from "./ClientTelephone/ClientTelephone.jsx";
import {useEffect} from "react";

const Antecedents = (props) => {
    const { data, id, initialValues, changeValue, codeProduit, source} = props;

    const antecedentsSur3ans =
        _.get(initialValues, 'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]') === '36' ||
        _.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]') === '36' ||
        _.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '1' ||
        _.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '1' ||
        _.get(data, 'DemandeTarif[Antecedents][ResiliationAssureur]') === '1' ||
        Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]')) > 1 ||
        Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]')) > 1;

    useEffect(() => {
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]', antecedentsSur3ans ? '36' : '24')
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', antecedentsSur3ans ? '36' : '24')
    }, [antecedentsSur3ans]);

    return (
        <div id={id}>
            {(
                _.includes(['quad', 'verte'], data?.typeVehicule) &&
                _.get(data, 'DemandeTarif[Police][MotifAvenant]') !== '1' &&
                _.get(initialValues, 'DemandeTarif[Vehicule][VehiculeImmatricule]') === undefined
            ) && (
                <VehiculeImmatricule title={'Votre véhicule est-il <b>immatriculé</b>&nbsp;?'} {...props} />
            )}
            {(
                _.includes(['quad', 'verte'], data?.typeVehicule) &&
                _.get(data, 'DemandeTarif[Police][MotifAvenant]') !== '1'
            ) && (
                <ValeurVehicule title={'Quelle est <b>la valeur</b> de votre <b>véhicule</b>&nbsp;?'} {...props} />
            )}


            {(Number(data?.cylindreeVehicule) > 50 && !_.includes(['7311_MOT1', '7311_PVK', '7311_PVE'], codeProduit)) &&
                <>
                    {_.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                        return _.includes(['AM', 'A1', 'A2', 'A', 'B'], permis?.TypePermis);
                    }) && <ValeurCrmMoto {...props} />}

                    {_.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                        return _.includes(['B'], permis?.TypePermis);
                    }) && <ValeurCrmAuto {...props} />}
                    {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                        _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                            return _.includes(['B'], permis?.TypePermis);
                        }) && <AssuranceAuto {...props} antecedentsSur3ans={antecedentsSur3ans} />}

                    {_.get(data, 'assure_auto') === '1' && (
                        <InformationsAssuranceAuto {...props} antecedentsSur3ans={antecedentsSur3ans} />
                    )}

                    {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                        _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                            return _.includes(['A', 'A1', 'A2', 'B'], permis?.TypePermis);
                        }) && <AssuranceMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />}

                    {_.get(data, 'assure_moto') === '1' && (
                        <InformationsAssuranceMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />
                    )}
                </>
            }

            <SuspensionPermis {...props} />
            {_.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '1' && (
                <InformationsSuspensionPermis {...props} />
            )}

            <AnnulationPermis {...props} />
            {_.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '1' && (
                <InformationsAnnulationPermis {...props} />
            )}

            {(_.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '1' ||
                _.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '1') && (
                <>
                    <CondamnationAlcoolemie {...props} />

                    {_.get(data, 'DemandeTarif[Antecedents][CondamnationAlcoolemie]') === '1' && (
                        <TauxAlcool {...props} />
                    )}
                    <CondamnationStupefiants {...props} />
                    <CondamnationDelitFuite {...props} />
                    <CondamnationConduiteSansAssurance {...props} />
                </>
            )}

            <ResiliationAncienAssureur {...props} />

            {_.get(data, 'DemandeTarif[Antecedents][ResiliationAssureur]') === '1' && (
                <InformationsResiliationAncienAssureur {...props} />
            )}

            {_.get(data, 'DemandeTarif[Police][MotifAvenant]') === '5' &&
                <Sinistres {...props} antecedentsSur3ans={antecedentsSur3ans} />
            }

            {_.get(data, 'sinistre_auto') === '1' && (
                <SinistresAutoMoto {...props} antecedentsSur3ans={antecedentsSur3ans} />
            )}

            {_.includes(['quad', 'verte'], data?.typeVehicule) && <MalusQuadVerte {...props} />}

            {source !== 'site' && <ClientTelephone {...props} /> }

            <GoToBesoin {...props} />
        </div>
    );
};

export default Antecedents;
