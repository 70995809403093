import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

const TitulaireMajeur = (props) => {
    const { data, changeValue, nextQuestion } = props;

    return (
        <>
            <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                <Field
                    component={Radio}
                    type={'radio'}
                    name={'titulaireCarteGrise'}
                    value={'1'}
                    label={'À votre nom'}
                    onChange={() => {
                        changeValue('DemandeTarif[ListePersonnes]', [
                            { ..._.get(data, 'DemandeTarif[ListePersonnes][0]') },
                        ]);
                        changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', true);
                        nextQuestion(300);
                    }}
                />
            </div>
            {!_.includes(['quad', 'verte'], data?.typeVehicule) && (
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'titulaireCarteGrise'}
                        value={'2'}
                        label={"Au nom d'une société"}
                        onChange={() => {
                            changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'M');
                            changeValue('DemandeTarif[ListePersonnes][1][Civilite]', '3');
                            changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true);
                            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false);
                            nextQuestion(300);
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default TitulaireMajeur;
