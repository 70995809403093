import warning from '@amo/core/assets/images/icons/warning.svg';
import { Question } from '@amo/core/components/containers';
import _ from 'lodash';
import {NumeroSerie, Immatriculation, ImmatOuMarquage} from "@amo/core/components/questions";

export const QuestionImmatriculation = (props) => {
    const { nextQuestion, data } = props;

    return (
        <Question icon={warning} title={"Quel est le <b>N° d'immatriculation</b> du véhicule&nbsp;?"}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-sm-6 col-12 my-2'}>
                    {_.get(data, 'immatriculation_check') === '0' ? (
                        <NumeroSerie {...props} />
                    ) : (
                        <Immatriculation {...props} />
                    )}
                    <ImmatOuMarquage {...props} />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
