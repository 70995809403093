import carte from '@amo/core/assets/images/AMO_Icons/Carte_Grise.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

export const CarteGriseFrancaise = (props) => {
    const { nextQuestion, data } = props;

    return (
        <Question icon={carte} title={'La <b>carte grise</b> du <b>véhicule</b><br/> est-elle <b>française</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][CarteGriseFrancaise]'}
                        value={'1'}
                        label={'Oui'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][CarteGriseFrancaise]'}
                        value={'0'}
                        label={'Non'}
                    />
                </div>
            </div>

            {_.get(data, 'DemandeTarif[Vehicule][CarteGriseFrancaise]') === '0' && (
                <div className="alert alert-danger mt-3 col-lg-8 offset-lg-2" role="alert">
                    Nous sommes désolés, nous n'assurons pas les véhicules possédant une carte grise étrangère.
                </div>
            )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    _.get(data, 'DemandeTarif[Vehicule][CarteGriseFrancaise]') !== '1' && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
